import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import React, { useCallback, useMemo } from "react";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";
import { Tag } from "../Rules/Tag";
import Autocomplete from "../util/Autocomplete";
import classNames from "classnames";
import { cb } from "../../utils/cd";
import { unpack } from "../../utils/unpack";
import { ArgSignature, Op, OpArgument, Ref, Val, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { interpolateValue } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { useOp } from "./OpUI";

export function Arg({
                      arg,
                      label,
                      argSignature,
                      allowSynthetic,
                      update,
                    }: {
  arg: OpArgument | null;
  label?: string;
  argSignature: ArgSignature;
  allowSynthetic?: boolean;
  update: (val: OpArgument | Op) => void;
}) {
  const { item, context } = useBuilderNGN();
  const { op } = useOp();

  const [availableRefs, refSuggestion] = useMemo(() => {
    if (Array.isArray(argSignature.value)) {
      const v: Ref[] = argSignature.value
        .map(
          v =>
            ({
              label: v,
              value: v,
              referenceId: "Synthetic",
              kind: v,
            }) as never,
        )
        .map((r: Ref) => {
          if (op.fn === ValueFunctions.RegExpMatches) {
            return r;
          }
          r.label = interpolateValue(r.label, getAllRefs(context()), "referenceId");
          return r;
        });

      return [v, v as any];
    }

    const lastGroupIdx = item.groups.length - 1;
    const refs = getAllRefs({
      me: { groupIdx: lastGroupIdx, questionIdx: item.groups[lastGroupIdx].questions.length },
      questionnaire: item,
    });
    const suggestions = refs
      // .filter(v => argType.includes(v.kind))
      .map(v => ({
        label: v.label,
        value: v.referenceId,
        kind: v.kind,
      }));

    return [refs, suggestions];
  }, [argSignature, context, item, op]);

  const setSelectedRef = useCallback(
    (ref: string | undefined) => {
      if (!ref) {
        return update(null as never);
      }
      const sel = availableRefs.find(v => v.referenceId === ref);
      if (sel) {
        return update({ ref: sel.referenceId });
      }

      update(ref.replace("new: ", ""));
    },
    [availableRefs, update],
  );

  const selectedRef: Ref | undefined = useMemo(() => {
    if (typeof arg === "string") {
      return {
        label:
          op.fn === ValueFunctions.RegExpMatches ? arg : interpolateValue(arg, getAllRefs(context()), "referenceId"),
        referenceId: "Synthetic",
      } as Ref;
    } else if (arg && typeof arg === "object" && "ref" in arg) {
      return availableRefs.find(v => v.referenceId.toLowerCase() === arg?.ref.toLowerCase()) as Ref;
    }

    if ([undefined, null].includes(arg as never)) {
      return undefined;
    }

    return {
      label: (arg as any).toString(),
      referenceId: "Synthetic",
    } as Ref;
  }, [arg, availableRefs, op, context]);

  const selectedRefLabel = useMemo(() => {
    return selectedRef?.label;
  }, [selectedRef]);

  if (![null, undefined].includes(selectedRef as never)) {
    return (
      <div className={"flex flex-col"}>
        <Tag
          label={selectedRefLabel as string}
          onRemove={() => setSelectedRef(undefined as never)}
          color={"bg-purple-600"}
        />{" "}
        {label && <span className="text-center text-gray-600 text-xs italic">{label}</span>}
      </div>
    );
  }

  switch (argSignature.value) {
    case Val.Bool: {
      return (
        <div className={"flex flex-col"}>
          <div className={"flex gap-2 items-center justify-evenly"}>
            <button
              className={classNames("flex w-20 border h-8 items-center justify-center text-center", {
                "bg-sky-500": selectedRefLabel === "True",
              })}
              onClick={cb(setSelectedRef, "True")}
            >
              True
            </button>
            <button
              className={classNames("flex w-20 border h-8 items-center justify-center text-center", {
                "bg-sky-500": selectedRefLabel === "False",
              })}
              onClick={cb(setSelectedRef, "False")}
            >
              False
            </button>
          </div>
          {label && <span className="text-center text-gray-600 text-xs italic">{label}</span>}
        </div>
      );
    }
    case Val.Date: {
      return (
        <div className={"flex flex-col"}>
          <div className={"flex gap-2 items-center"}>
            <input
              type="date"
              className="w-full px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onBlur={unpack(setSelectedRef)}
            />
          </div>
          {label && <span className="text-center text-gray-600 text-xs italic">{label}</span>}
        </div>
      );
    }

    case Val.Number: {
      return (
        <div className={"flex flex-col"}>
          <div className={"flex gap-2 items-center"}>
            <input
              type="number"
              className="w-full px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onBlur={unpack(setSelectedRef)}
            />
          </div>
          {label && <span className="text-center text-gray-600 text-xs italic">{label}</span>}
        </div>
      );
    }
    case Val.String: {
      return (
        <div className={"flex flex-col"}>
          <div className={"flex gap-2 items-center "}>
            <input
              className="w-full px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onBlur={unpack(setSelectedRef)}
            />
          </div>
          {label && <span className="text-center text-gray-600 text-xs italic">{label}</span>}
        </div>
      );
    }
    case "op": {
      return <div>Item Function</div>;
    }
  }

  return (
    <div className={"flex flex-col"}>
      <div className={"flex gap-2 items-center "}>
        <Autocomplete
          suggestions={refSuggestion}
          onSelect={setSelectedRef}
          placeholder={"start typing..."}
          allowCreate={true}
        />
      </div>
      {label && <span className="text-center text-gray-600 text-xs italic">{label}</span>}
    </div>
  );
}

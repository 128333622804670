import React, { useMemo } from "react";
import { v4 } from "uuid";
import { useBuilderNGN } from "./BuilderNGN";
import { CarrierDefault } from "@Savus-Inc/questionnaire-types";
import { useAppState } from "../../state/State";

export const UploadDefaults = ({ carrierId }: { carrierId: string }) => {
  const { state: { carriers } } = useAppState();
  const { bulkSetCarrierDefaults, item } = useBuilderNGN();
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type?.toLowerCase()?.includes("json")) {
      const data = JSON.parse(await file?.text());
      bulkSetCarrierDefaults(
        carrierId,
        data.map((v: CarrierDefault) => {
          v.id = v4();
          return v;
        }),
      );
      return;
    }
    alert("CSV Import is not yet supported");
  };

  const carrier = useMemo(() => carriers.find(v => v.id === carrierId)?.name || "unknown", [carriers, carrierId]);
  const defaults = useMemo(() => JSON.stringify(item.carrierQuestionnaires.find(q => q.carrierId === carrierId)?.defaults || []), [carrierId, item]);

  return (
    <div className="flex items-center gap-2 mb-2">
      <a
        className="py-1.5 px-3 rounded-xl border border-gray-200 text-sky-600 "
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          defaults,
        )}`}
        download={`${carrier}-defaults.json`}
      >
        Download
      </a>
      <div className="w-full max-w-lg flex flex-col justify-center ">
        <input
          type="file"
          accept=".csv,.json"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 bg-gray-50  px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          "
        />
      </div>
    </div>
  );
};

import React, { useMemo } from "react";
import Papa, { ParseResult } from "papaparse";
import { useBuilderNGN } from "./BuilderNGN";
import { CarrierQuestion, QuestionnaireQuestionEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { v4 } from "uuid";
import { ComplexOperator, Op, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { toast } from "react-toastify";
import { useAppState } from "../../state/State";
import { calculateQuestionExternalIdMap } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { OpArgument } from "@Savus-Inc/dsl/src/types/op";
import { QuestionExternalIdMap } from "@Savus-Inc/questionnaire-ngn/dist/utils/calculate-question-externalId-map";

export function overrideOpReference(vr: Op, questionMap: QuestionExternalIdMap) {
  try {
    for (const ag of vr.args) {
      if ((ag as Op)?.fn) {
        overrideOpReference(ag as Op, questionMap);
      } else if (ag) {
        const a = ag as OpArgument;
        if (a && typeof a === "object") {
          a.ref = questionMap.map[a.ref as string] || a.ref;
        }
      }
    }

    if (vr.condition) {
      vr.condition.rules.forEach(r => {
        overrideOpReference(r.value, questionMap);
      });
    }
  } catch (e) {
    console.log(vr);
    console.error(e);
    throw e;
  }

}

export const transformPathVal = (v: string) => {
  const handlePlural = (v: string) => {
    if (
      ["insureds", "locations", "buildings", "classes", "coverages", "questions", "losses", "additionalInterests"].some(
        w => v.includes(w) && !v.endsWith("]"),
      )
    ) {
      return [`${v.trim()}[]`];
    } else {
      return [v.trim()];
    }
  };
  if (v.includes("='Primary'")) {
    const str = v.substring(0, v.indexOf("["));
    return [handlePlural(str), "0"].flat(9);
  } else if (v.includes("='Additional'")) {
    const str = v.substring(0, v.indexOf("["));
    return [handlePlural(str), "__Additional"].flat(9);
  } else {
    return [handlePlural(v)].flat(9);
  }
};

type CSVNode = Record<"jsonNode" | "questionId" | "answerValue" | "answerLabel" | "questionCode" | "carrier" | "delete", string>;
export const UploadMapping = ({ carrierId }: { carrierId: string }) => {
  const { item, bulkSetCarrierMapping, questions } = useBuilderNGN();
  const {
    state: { carriers },
  } = useAppState();
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const questionMap = calculateQuestionExternalIdMap(item);
    if (file?.type?.toLowerCase()?.includes("json")) {
      const data: CarrierQuestion[] = JSON.parse(await file?.text());
      bulkSetCarrierMapping(
        carrierId,
        data
          .filter(
            v =>
              v.jsonNode.length &&
              v.valueRules.length &&
              !(v.valueRules[0].fn === ValueFunctions.Evaluate && v.valueRules[0].args[0] === "False") && (!v.referencedQuestionId || !!questionMap.inverse[v.referencedQuestionId]),
          )
          .map(v => {
            if (v.referencedQuestionId) {
              v.valueRules.map(vr => {
                if (vr.fn === ValueFunctions.KeyValue && !vr.condition) {
                  vr.condition = {
                    op: ComplexOperator.Standalone,
                    kind: RuleGroupKind.Knockout,
                    rules: [
                      {
                        op: ComplexOperator.Standalone,
                        value: {
                          fn: ValueFunctions.Unanswered,
                          args: [
                            {
                              ref: v.referencedQuestionId,
                            },
                            "False",
                          ],
                        },
                      },
                    ],
                  } as never;
                }

                return vr;
              });
            }

            v.valueRules.forEach(v => overrideOpReference(v, questionMap));

            v.referencedQuestionId = questionMap.map[v.referencedQuestionId as string] || v.referencedQuestionId;
            v.jsonNode = v.jsonNode.map(js => {
              const hasClassesAndExposures =
                js.includes("classes[]") && js.includes("exposure[]") && !js.includes("questions[]");
              const hasBuildingAssignment = js.includes("locations[]") && js.includes("buildings[]");

              const locationUWParent = questionMap.inverse["Q-2108"];
              let isLocationUW = v.referencedQuestionId ? questions.find(q => q.externalId === v.referencedQuestionId)?.parentId === locationUWParent : false;

              const hasCoverages = js.includes("coverages") && js.includes("questions[]");

              return js.map(k => k === "locations[]" && isLocationUW ? `locations[locationId={Q-2110}]` :
                k === "locations[]" && hasBuildingAssignment
                  ? `locations[${js.includes("quotesInfo") ? "locationId" : "id"}={Q-52}]`
                  : hasClassesAndExposures && k === "exposure[]"
                    ? "exposure"
                    : hasCoverages && k === "coverages"
                      ? "coverages[]"
                      : k,
              );
            });

            v.id = v4();
            return v;
          }),
      );
      return;
    }
    if (file) {
      Papa.parse(file, {
        header: true,
        complete(results: ParseResult<any>) {
          const carrierQuestionnaire = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
          const carrierName = carriers.find(c => c.id === carrierId)?.name;
          if (!carrierName || !carrierQuestionnaire) {
            return;
          }

          if (window.confirm("Do you want to remove all previous mappings for the carrier?")) {
            carrierQuestionnaire.questions = [];
          }
          const csvData = results.data as CSVNode[];
          const questions: Record<string, QuestionnaireQuestionEntity> = item.groups
            .flatMap(g => g.questions)
            .reduce((p, c) => ({ ...p, [c?.question?.externalId || ""]: c }), {});

          let detectedNewMapping = 0;
          const questionMaps = csvData
            .filter(v => {
              return (
                v.questionId &&
                !v.delete?.trim() &&
                v.carrier === carrierName &&
                !carrierQuestionnaire.questions.some(m => m.referencedQuestionId === questions[v.questionId]?.id)
              );
            })
            .reduce(
              (p, c) => {
                if (p[c.questionId]) {
                  p[c.questionId].push(c);
                } else {
                  p[c.questionId] = [c];
                }
                return p;
              },
              {} as Record<string, CSVNode[]>,
            );

          Object.entries(questionMaps).forEach(([_, v]) => {
            let valueRules: CarrierQuestion["valueRules"] = [];
            const master = v.find(v => v.jsonNode && v.jsonNode !== "n/a");

            v.filter(v => v.jsonNode && v.jsonNode !== "n/a").forEach(master => {
              if (
                !master ||
                !questions[master.questionId] ||
                (master.jsonNode.endsWith(".code") && !master.questionCode) ||
                !master.jsonNode.trim()
              ) {
                return;
              }

              // ownValues
              if (v.length > 1 && v.some(v => v.answerValue.trim() !== v.answerLabel.trim())) {

                valueRules.push(
                  ...v
                    .filter(v => v.answerValue)
                    .map(v => ({
                      fn: master.questionCode ? ValueFunctions.KeyValue : ValueFunctions.Value,
                      args: master.questionCode
                        ? [
                          !carrierName.toLowerCase().includes("ubb") ? "questionId" : "code",
                          master.questionCode,
                          !carrierName.toLowerCase().includes("ubb") ? "answer" : "value",
                          v.answerValue,
                        ]
                        : [v.answerValue],
                      condition: {
                        op: ComplexOperator.Standalone,
                        kind: RuleGroupKind.Knockout,
                        rules: [
                          {
                            op: ComplexOperator.Standalone,
                            value: {
                              fn: ValueFunctions.Is,
                              args: [
                                {
                                  ref: v.questionId,
                                },
                                v.answerLabel,
                              ],
                            },
                          },
                        ],
                      },
                    })),
                );
              } else if (master.questionCode) {
                valueRules.push({
                  fn: ValueFunctions.KeyValue,
                  args: [
                    !carrierName.toLowerCase().includes("ubb") ? "questionId" : "code",
                    master.questionCode,
                    !carrierName.toLowerCase().includes("ubb") ? "answer" : "value",
                    { ref: master.questionId },
                  ],
                });
              } else {
                valueRules.push({
                  fn: ValueFunctions.Value,
                  args: [
                    {
                      ref: master.questionId,
                    },
                  ],
                });
              }

            });

            const map: CarrierQuestion = {
              id: v4(),
              referencedQuestionId: master?.questionId,
              jsonNode:
                master?.jsonNode?.split(",").map(
                  jsonNode =>
                    jsonNode
                      ?.split(".")
                      .flatMap(transformPathVal)
                      .filter(v => v !== "code") || [],
                ) || [],
              valueRules,
            };

            if (!carrierQuestionnaire.questions.some(v => v.referencedQuestionId === map.referencedQuestionId)) {
              detectedNewMapping += 1;
              carrierQuestionnaire.questions.push(map);
            }
          });

          const payload = carrierQuestionnaire.questions.map(qs => {
            qs.jsonNode = qs.jsonNode.map(v => {
              const hasClassesAndExposures =
                v.includes("classes[]") && v.includes("exposure[]") && !v.includes("questions[]");
              const hasBuildingAssignment = v.includes("locations[]") && v.includes("buildings[]");

              const locationUWParent = questionMap.inverse["Q-2108"];
              let isLocationUW = qs.referencedQuestionId ? Object.values(questions).find(q => q.externalId === qs.referencedQuestionId)?.parentId === locationUWParent : false;

              const hasCoverages = v.includes("coverages") && v.includes("questions[]");

              return v.map(k => k === "locations[]" && isLocationUW ? `locations[locationId={Q-2110}]` :
                k === "locations[]" && hasBuildingAssignment
                  ? `locations[${v.includes("quotesInfo") ? "locationId" : "id"}={Q-52}]`
                  : hasClassesAndExposures && k === "exposure[]"
                    ? "exposure"
                    : hasCoverages && k === "coverages"
                      ? "coverages[]"
                      : k,
              );
            });

            if (!qs.valueRules.length) {
              console.log("Wrong question rules for the questions for this question");
              console.log(qs);
            }
            return qs;
          });

          if (
            !window.confirm(
              `You are about to add ${detectedNewMapping} mappings for ${carrierName}, do you wish to continue?`,
            )
          ) {
            return toast.info("Action aborted");
          }
          bulkSetCarrierMapping(carrierId, payload);

          toast.info(`Detected ${detectedNewMapping} new mappings.`);
        },
      });
    }
  };

  const carrier = useMemo(() => carriers.find(v => v.id === carrierId)?.name || "unknown", [carriers, carrierId]);
  const mapping = useMemo(() => JSON.stringify(item.carrierQuestionnaires.find(q => q.carrierId === carrierId)?.questions || []), [carrierId, item]);

  return (
    <div className="flex gap-2 items-center">
      <a
        className="w-1/2 px-3 rounded-xl border border-gray-200 text-sky-600 py-1.5"
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          mapping,
        )}`}
        download={`${carrier}-mapping.json`}
      >
        Download
      </a>
      <div className="w-full max-w-lg flex flex-col justify-center relative">
        <input
          type="file"
          accept=".csv,.json"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 bg-gray-50 px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          "
        />
      </div>
    </div>
  );
};

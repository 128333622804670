import { CustomerRecord } from "../../types/customer-record";
import { QuoteDetails } from "../shared/QuoteDetails";

export const TestQuoteDetails = ({ 
    customerRecord, 
    handleClose 
}: {
    customerRecord: CustomerRecord;
    handleClose: () => void;
}) => {
    // Use the first questionnaire state if it exists
    const questionnaireState = customerRecord.questionnaireState?.[0];

    if (!questionnaireState) {
        return <div>No questionnaire state found</div>;
    }

    return (
        <QuoteDetails
            questionnaireState={questionnaireState}
            customerInfo={customerRecord}
            handleClose={handleClose}
        />
    );
};

import { Op, Ref, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { getFunctionSignature } from "@Savus-Inc/dsl/dist/utils";
import * as Monaco from "monaco-editor";
import { editor } from "monaco-editor";
import { useEffect, useRef, useState } from "react";
import { stringToOp, stringToRuleGroup } from "@Savus-Inc/dsl/dist/utils/transformer";
import { toast } from "react-toastify";
import { RuleGroupEntity } from "@Savus-Inc/questionnaire-types";

type RuleEditorProps = {
  value: string;
  variables: Ref[];
} & ({
  onComplete?: (value: RuleGroupEntity[]) => void;
  kind: "rg"
} | {
  onComplete?: (value: Op) => void;
  kind: "op"
})

export const RuleEditor = ({ value, variables, onComplete, kind }: RuleEditorProps) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const monacoRef = useRef<typeof Monaco | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (!variables.length) return;
    if (!monacoRef.current && containerRef.current) {
      import("monaco-editor").then(monaco => {
        monacoRef.current = monaco;

        // Register custom language
        monaco.languages.register({ id: "ruleLanguage" });

        // Define syntax highlighting
        monaco.languages.setMonarchTokensProvider("ruleLanguage", {
          tokenizer: {
            root: [
              [/\$[^,)\s]+/, "variable"], // Match $ followed by any chars until delimiter
              [/[A-Z][a-zA-Z]*(?=\()/, "function"],
              [/[0-9]+/, "number"],
              [/".*?"/, "string"],
              [/\[|\]|\(|\)/, "delimiter"],
              [/,/, "delimiter"],
              [/And|Or|Not/, "keyword"],
            ],
          },
        });

        // Configure autocompletion
        monaco.languages.registerCompletionItemProvider("ruleLanguage", {
          triggerCharacters: ["$"],
          provideCompletionItems: (model: Monaco.editor.ITextModel, position: Monaco.Position, context: Monaco.languages.CompletionContext, token: Monaco.CancellationToken): Monaco.languages.ProviderResult<Monaco.languages.CompletionList> => {
            const lineContent = model.getLineContent(position.lineNumber);
            const textUntilPosition = lineContent.substring(0, position.column);

            const functionValues = Object.values(ValueFunctions);
            const funcMatch = textUntilPosition.match(/([A-Z][a-zA-Z]*)?$/);
            const partialFunc = funcMatch?.[1] || "";
            const range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: position.column - partialFunc.length,
              endColumn: position.column,
            };

            return {
              suggestions: [...functionValues.map(fn => {
                const signature = getFunctionSignature(fn);
                const params = signature.argSignature.map((arg, index) => {
                  const label = signature.argumentLabel?.[index] || `arg${index + 1}`;
                  return `\${${index + 1}:${label}}`;
                }).join(", ");

                return {
                  label: fn,
                  kind: monaco.languages.CompletionItemKind.Function,
                  insertText: `${fn}(${params})`,
                  insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                  detail: `(${signature.argSignature.map((arg, i) =>
                    `${signature.argumentLabel?.[i] || `arg${i + 1}`}: ${arg.value}`,
                  ).join(", ")})`,
                  documentation: {
                    value: `Function ${fn}\n\nArguments:\n${
                      signature.argSignature.map((arg, i) =>
                        `- ${signature.argumentLabel?.[i] || `arg${i + 1}`}: ${
                          Array.isArray(arg.value) ? arg.value.join(" | ") : arg.value
                        }`,
                      ).join("\n")
                    }`,
                  },
                  range,
                };
              }), ...variables.map(v => ({
                label: `${v.referenceId.replace("-", "_")}`,
                kind: monaco.languages.CompletionItemKind.Variable,
                insertText: `${v.referenceId}`,
                range,
              }))],
            };
          },
        });

        // Create editor only if it doesn't exist
        if (!editorRef.current) {
          editorRef.current = monaco.editor.create(containerRef.current!, {
            value,
            language: "ruleLanguage",
            theme: "vs-light",
            minimap: { enabled: false },
            automaticLayout: true,
            fontSize: 14,
            lineHeight: 21,
          });

          editorRef.current.onDidChangeModelContent(() => {
            setCurrentValue(editorRef.current?.getValue() || "");
          });
        }
      });
    }

    return () => {
      editorRef.current?.dispose();
      editorRef.current = null;
    };
  }, []); // Empty dependency array

  const handleSave = () => {
    try {
      if (kind === "rg") {
        const v = stringToRuleGroup(currentValue);

        onComplete?.(v as RuleGroupEntity[]);
      } else {
        const v = stringToOp(currentValue);

        onComplete?.(v);
      }

    } catch (error: any) {
      toast.error(error.message || error.toString());
    }

  };

  return (
    <div className="flex flex-col gap-4 relative w-full">
      <div ref={containerRef} style={{ height: "300px", border: "1px solid #ccc" }} />
      <div className="flex items-center gap-2 justify-end">

        <button
          onClick={handleSave}
          className="text-sm text-gray-50 w-32 bg-teal-500 px-2 py-1 rounded-md absolute -top-9 right-"
        >
          Save
        </button>
      </div>
    </div>
  );
}; 
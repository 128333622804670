import { QuestionnaireState } from "../../types/customer-record";
import { QuoteDetails } from "../shared/QuoteDetails";

interface QuoteReportProps {
    questionnaireState: QuestionnaireState;
    onClose: () => void;
}

export const QuoteReport = ({ questionnaireState, onClose }: QuoteReportProps) => {
    return (
        <QuoteDetails
            questionnaireState={questionnaireState}
            handleClose={onClose}
        />
    );
}; 
import React, { useState } from "react";
import { JsonToCsv } from "./components/JsonToCsv";
import { JsonDiff } from "./components/JsonDiff";

export const UtilsPage = () => {
  const [activeTab, setActiveTab] = useState<"jsonToCsv" | "jsonDiff">("jsonToCsv");

  return (
    <div className="w-full flex flex-col gap-2 h-calc(100vh - 120px) mt-4">
      <div className="flex gap-2 px-4 flex-1">
        <div
          className="w-64 flex flex-col gap-2 bg-white h-calc(100vh - 120px) overflow-y-auto px-4 py-2 rounded-lg shadow">
          <button
            className={`p-2 rounded-lg ${
              activeTab === "jsonToCsv" ? "bg-primary text-white" : "bg-light hover:bg-secondary-lighter"
            }`}
            onClick={() => setActiveTab("jsonToCsv")}
          >
            JSON to CSV
          </button>
          <button
            className={`p-2 rounded-lg ${
              activeTab === "jsonDiff" ? "bg-primary text-white" : "bg-light hover:bg-secondary-lighter"
            }`}
            onClick={() => setActiveTab("jsonDiff")}
          >
            JSON Diff
          </button>
        </div>

        <div className="flex-1 bg-white rounded-lg shadow">
          {activeTab === "jsonToCsv" ? (
            <JsonToCsv />
          ) : (
            <JsonDiff />
          )}
        </div>
      </div>
    </div>
  );
};


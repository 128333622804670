import { ApiResponse } from "../types/ApiResponse";
import {
  CarrierDefault,
  CarrierQuestion,
  CarrierQuestionnaire,
  Question,
  Questionnaire,
  QuestionnaireGroupSection,
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionGroupEntity,
  QuestionValueOption,
  RuleGroupActionMetadata,
  RuleGroupEntity,
  RuleGroupKind,
} from "@Savus-Inc/questionnaire-types";
import { makeRequest } from "./makeRequest";
import { LocationBusinessType } from "@Savus-Inc/runtime-ngn/dist/src/ngn";

export const listQuestionnaires = async (): Promise<ApiResponse<Record<"data", Questionnaire[]>>> => {
  return makeRequest("get", "data/referent/questionnaire");
};

export const getQuestionnaire = async (questionnaireId: string): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("get", `data/referent/questionnaire/${questionnaireId}`);
};

export const getQuestionnaireCarrierMapping = async (
  questionnaireId: string,
): Promise<ApiResponse<CarrierQuestionnaire[]>> => {
  return makeRequest("get", `data/referent/questionnaire/${questionnaireId}/mapping`);
};

export const createQuestionnaire = async (
  data: Pick<Questionnaire, "name" | "title" | "subText">,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("post", "data/referent/questionnaire", data);
};

export const removeQuestionnaire = async (questionnaireId: string): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("delete", `data/referent/questionnaire/${questionnaireId}`);
};

export const recoverQuestionnaire = async (questionnaireId: string): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("patch", `data/referent/questionnaire/${questionnaireId}/recover`, {});
};

export const publishQuestionnaire = async (questionnaireId: string): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("patch", `data/referent/questionnaire/${questionnaireId}/publish`, {});
};

export const editQuestionnaire = async (
  questionnaireId: string,
  questionnaire: Questionnaire,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("patch", `data/referent/questionnaire/${questionnaireId}`, questionnaire);
};

export const questionnaireBulkInsert = async (
  questionnaireId: string,
  questionnaire: Questionnaire,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("patch", `data/referent/questionnaire/${questionnaireId}/bulk-insert`, questionnaire);
};

export const addPage = async (
  questionnaireId: string,
  data: Partial<QuestionnaireQuestionGroupEntity>,
): Promise<ApiResponse<QuestionnaireQuestionGroupEntity>> => {
  return makeRequest("post", `data/referent/questionnaire/${questionnaireId}/question-group`, data);
};

export const editPage = async (
  questionnaireId: string,
  pageId: string,
  data: Partial<QuestionnaireQuestionGroupEntity>,
): Promise<ApiResponse<QuestionnaireQuestionGroupEntity>> => {
  return makeRequest("patch", `data/referent/questionnaire/${questionnaireId}/question-group/${pageId}`, data);
};

export const deletePage = async (questionnaireId: string, pageId: string): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("delete", `data/referent/questionnaire/${questionnaireId}/question-group/${pageId}`);
};

export const addSection = async (
  questionnaireId: string,
  groupId: string,
  data: Partial<QuestionnaireGroupSection>,
): Promise<ApiResponse<QuestionnaireGroupSection>> => {
  return makeRequest("post", `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/sections`, data);
};

export const editSection = async (
  questionnaireId: string,
  groupId: string,
  sectionId: string,
  data: Partial<QuestionnaireQuestionGroupEntity>,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/sections/${sectionId}`,
    data,
  );
};

export const deleteSection = async (
  questionnaireId: string,
  groupId: string,
  sectionId: string,
): Promise<ApiResponse<{ message: string }>> => {
  return makeRequest(
    "delete",
    `data/referent/questionnaire/${questionnaireId}/question-group//${groupId}/sections/${sectionId}`,
  );
};

export const addReferentQuestion = async (data: Partial<Question>): Promise<ApiResponse<Question>> => {
  return makeRequest("post", "data/referent/question", data);
};

export const editReferentQuestion = async (
  questionId: string,
  data: Partial<Question>,
): Promise<ApiResponse<Question>> => {
  return makeRequest("patch", `data/referent/question/${questionId}`, data);
};

export const removeReferentQuestion = async (questionId: string): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("delete", `data/referent/question/${questionId}`);
};

export const addReferentQuestionValue = async (
  questionId: string,
  data: Partial<QuestionValueOption>,
): Promise<ApiResponse<QuestionValueOption>> => {
  return makeRequest("post", `data/referent/question/${questionId}/value`, data);
};

export const editReferentQuestionValue = async (
  questionId: string,
  valueId: string,
  data: Partial<QuestionValueOption>,
): Promise<ApiResponse<QuestionValueOption>> => {
  return makeRequest("patch", `data/referent/question/${questionId}/value/${valueId}`, data);
};

export const removeReferentQuestionValue = async (
  questionId: string,
  valueId: string,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("delete", `data/referent/question/${questionId}/value/${valueId}`);
};

export const addReferentQuestionValueVisibilityGroup = async (
  questionId: string,
  valueId: string,
  data: Partial<RuleGroupEntity>,
): Promise<ApiResponse<QuestionValueOption>> => {
  return makeRequest("post", `data/referent/question/${questionId}/value/${valueId}/rules`, data);
};

export const editReferentQuestionValueVisibilityGroup = async (
  questionId: string,
  valueId: string,
  groupId: string,
  data: Partial<QuestionValueOption>,
): Promise<ApiResponse<QuestionValueOption>> => {
  return makeRequest("patch", `data/referent/question/${questionId}/value/${valueId}/rules/${groupId}`, data);
};

export const removeReferentQuestionValueVisibilityGroup = async (
  questionId: string,
  valueId: string,
  groupId: string,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest("delete", `data/referent/question/${questionId}/value/${valueId}/rules/${groupId}`);
};

export const addQuestion = async (
  questionnaireId: string,
  groupId: string,
  data: Partial<QuestionnaireQuestionEntity>,
): Promise<ApiResponse<QuestionnaireQuestionEntity>> => {
  return makeRequest("post", `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question`, data);
};

export const editQuestion = async (
  questionnaireId: string,
  groupId: string,
  questionId: string,
  data: Partial<QuestionnaireQuestionEntity>,
): Promise<ApiResponse<QuestionnaireQuestionEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question/${questionId}`,
    data,
  );
};

export const removeQuestion = async (
  questionnaireId: string,
  groupId: string,
  questionId: string,
): Promise<ApiResponse<Questionnaire>> => {
  return makeRequest(
    "delete",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question/${questionId}`,
  );
};

export const addQuestionRuleGroup = async (
  questionnaireId: string,
  groupId: string,
  questionId: string,
  data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question/${questionId}/rule-groups`,
    data,
  );
};

export const editQuestionRuleGroup = async (
  questionnaireId: string,
  groupId: string,
  questionId: string,
  ruleGroupId: string,
  data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question/${questionId}/rule-groups/${ruleGroupId}`,
    data,
  );
};

export const removeQuestionRuleGroup = async (
  questionnaireId: string,
  groupId: string,
  questionId: string,
  ruleGroupId: string,
  kind: RuleGroupKind,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "delete",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question/${questionId}/rule-groups/${ruleGroupId}/${kind}`,
  );
};

export const updateRuleGroupRules = async (
  questionnaireId: string,
  groupId: string,
  questionId: string,
  ruleGroupId: string,
  kind: RuleGroupKind,
  rules: RuleGroupEntity["rules"],
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/question-group/${groupId}/question/${questionId}/rule-groups/${ruleGroupId}/${kind}/rules`,
    { rules },
  );
};

export const addKnockoutRule = async (
  questionnaireId: string,
  carrierId: string,
  data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/knockout-rules/`,
    data,
  );
};

export const editKnockoutRule = async (
  questionnaireId: string,
  carrierId: string,
  ruleGroupId: string,
  data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/knockout-rules/${ruleGroupId}`,
    data,
  );
};

export const removeKnockoutRule = async (
  questionnaireId: string,
  carrierId: string,
  ruleGroupId: string,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "delete",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/knockout-rules/${ruleGroupId}`,
  );
};

export const updateKnockoutRuleRules = async (
  questionnaireId: string,
  carrierId: string,
  ruleGroupId: string,
  rules: RuleGroupEntity["rules"],
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/knockout-rules/${ruleGroupId}/rules`,
    { rules },
  );
};

export const bulkAddCarrierKnockoutRules = async (
  questionnaireId: string,
  carrierId: string,
  data: { items: Partial<CarrierQuestion>[] },
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/knockout-rules/bulk-insert`,
    data,
  );
};

export const addCarrierDefault = async (
  questionnaireId: string,
  carrierId: string,
  data: Partial<CarrierDefault>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/defaults/`,
    data,
  );
};

export const editCarrierDefault = async (
  questionnaireId: string,
  carrierId: string,
  defaultsId: string,
  data: Partial<CarrierDefault>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/defaults/${defaultsId}`,
    data,
  );
};

export const removeCarrierDefault = async (
  questionnaireId: string,
  carrierId: string,
  defaultsId: string,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "delete",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/defaults//${defaultsId}`,
  );
};

export const addCarrierDataMap = async (
  questionnaireId: string,
  carrierId: string,
  data: Partial<CarrierQuestion>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/data-map/`,
    data,
  );
};

export const editCarrierDataMap = async (
  questionnaireId: string,
  carrierId: string,
  dataMapId: string,
  data: Partial<CarrierQuestion>,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "patch",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/data-map/${dataMapId}`,
    data,
  );
};

export const removeCarrierDataMap = async (
  questionnaireId: string,
  carrierId: string,
  dataMapId: string,
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "delete",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/data-map/${dataMapId}`,
  );
};

export const bulkAddCarrierDataMap = async (
  questionnaireId: string,
  carrierId: string,
  data: { items: Partial<CarrierQuestion>[] },
): Promise<ApiResponse<RuleGroupEntity>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/data-map/bulk-insert`,
    data,
  );
};

export const bulkAddCarrierDefaults = async (
  questionnaireId: string,
  carrierId: string,
  data: { items: Partial<CarrierDefault>[] },
): Promise<ApiResponse<CarrierDefault>> => {
  return makeRequest(
    "post",
    `data/referent/questionnaire/${questionnaireId}/carrier-mapping/${carrierId}/defaults/bulk-insert`,
    data,
  );
};

export const getBusinessTypes = async (
  term: string,
): Promise<ApiResponse<LocationBusinessType[]>> => {
  return makeRequest(
    "get",
    `questionnaire//question-integration/location-business-type?term=${term}`,
  );
};

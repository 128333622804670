import { ApiResponse } from "../types/ApiResponse";
import { makeRequest } from "./makeRequest";
import { CustomerRecord } from "../types/customer-record";
import { CarrierSubmission } from "@Savus-Inc/questionnaire-ngn/dist/types";

export const listCustomerRecords = async (
  limit = 100,
  offset = 0,
): Promise<ApiResponse<{ data: CustomerRecord[] }>> => {
  return makeRequest("get", `data/agency/customers?limit=${limit}&offset=${offset}`);
};

export const getCustomerRecord = async (customerId: string): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest("get", `data/agency/customers/${customerId}`);
};
export const createCustomerRecord = async (data?: CustomerRecord): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest("post", `data/agency/customers`, data);
};

export const listPreviousQuotes = async (applicationId: string): Promise<ApiResponse<{ data: any[] }>> => {
  return makeRequest("get", `questionnaire/${applicationId}?limit=100&offset=0`);
};

export const startQuote = async (
  applicationId: string,
  quoteStateId: string,
  customerRecordId: string,
  products: { carrierId: string; lineOfBusinessId: string }[],
  prefillQuoteId?: string,
): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest(
    "put",
    `questionnaire/${applicationId}/${quoteStateId}?customerRecordId=${customerRecordId}${
      prefillQuoteId ? `&prefillQuoteId=${prefillQuoteId}` : ""
    }`,
    { products },
  );
};

export const createQuote = async (
  applicationId: string,
  customerRecordId: string,
): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest("post", `questionnaire/${applicationId}/create-quote`, { customerRecordId });
};

export const submitQuote = async (
  applicationId: string,
  quoteStateId: string,
  customerRecordId: string,
  submissions: CarrierSubmission[],
): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest(
    "post",
    `questionnaire/${applicationId}/${quoteStateId}/submit?customerRecordId=${customerRecordId}`,
    { data: submissions },
  );
};

export const getCustomerRecordExtendedQuoteDetails = async (
  customerRecordId: string,
): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest("get", `data/agency/customers/extended-quote-info/quote?customerRecordId=${customerRecordId}`);
};

export const getCustomerRecordMarkers = async (): Promise<ApiResponse<CustomerRecord[]>> => {
  return makeRequest("get", `data/agency/customers/extended-quote-info/marker`);
};

export const getCustomerRecordMarkerQuotesData = async (marker: string): Promise<ApiResponse<CustomerRecord[]>> => {
  return makeRequest("get", `data/agency/customers/extended-quote-info/marker/${marker}`);
};

export const getCoverage = async (applicationId: string, questionnaireStateId?: string): Promise<ApiResponse<any>> => {
  return makeRequest("get", `questionnaire/${applicationId}/${questionnaireStateId}/coverage`);
};

export const saveQuoteState = async (
  applicationId: string,
  quoteStateId: string,
  customerRecordId: string,
  answers: any,
) => {
  return makeRequest("patch", `questionnaire/${applicationId}/${quoteStateId}?customerRecordId=${customerRecordId}`, {
    answers,
    page: 0,
  });
};

export const getAvailableAgencies = async (): Promise<ApiResponse<any>> => {
  return makeRequest("get", `data/agency/registry`);
};

export const getBusinessReports = async (tenantId: string, from: string, to: string, branchId?: string, userId?: string): Promise<ApiResponse<any>> => {
  return makeRequest("get", `data/agency/reports?from=${from}&to=${to}${branchId ? `&branchId=${branchId}` : ''}${userId ? `&userId=${userId}` : ''}`, undefined, tenantId);
};

export const getQuoteDetails = async (quoteId: string): Promise<ApiResponse<any>> => {
  return makeRequest("get", `data/agency/reports/report/${quoteId}`);
};

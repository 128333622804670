import { ReactNode } from "react";
import classNames from "classnames";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  size?: "sm" | "md" | "lg";
}

export const Modal = ({ isOpen, onClose, children, title, size = "lg" }: ModalProps) => {
  if (!isOpen) return null;

  const sizeClasses = {
    sm: "max-w-md",
    md: "max-w-lg",
    lg: "max-w-2xl",
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        {/* Backdrop */}
        <div
          className="fixed inset-0 bg-dark bg-opacity-50 transition-opacity"
          onClick={onClose}
        />

        {/* Modal */}
        <div
          className={classNames(
            "relative bg-white rounded-lg shadow-lg w-full animate-fade-in",
            sizeClasses[size],
          )}
        >
          {/* Header */}
          {title && (
            <div className="border-b border-divider px-6 py-4">
              <h2 className="text-lg font-semibold text-dark-blue">{title}</h2>
            </div>
          )}

          {/* Content */}
          <div className="px-6 py-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

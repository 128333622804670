import React, { useCallback, useReducer, useState } from "react";
import { RuleGroupOwner, useBuilderNGN } from "./BuilderNGN";
import { RuleGroups } from "../Rules/RuleGroup";
import { NoData } from "../util/Empty";
import { RuleGroupEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { CommonValidationEnum, modalCommonValidations, resolveValidationGroup } from "../../utils/commonValidations";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import { AddIcon } from "../util/AddNew";
import { cb } from "../../utils/cd";
import { Modal } from "../util/Modal";
import ValidationArguments, { DateData, NumberData, StringData, ValidationData } from "./ValidationArguments";

const QUESTION_RULE_GROUPS = [
  {
    label: "Validation",
    value: RuleGroupKind.Validation,
  },
  {
    label: "Default Value",
    value: RuleGroupKind.PreFill,
  },
  {
    label: "Display Logic",
    value: RuleGroupKind.Visibility,
  },
  {
    label: "Lock Answer Rules",
    value: RuleGroupKind.LockAnswer,
  },
];

const GROUP_RULE_GROUPS = [
  {
    label: "Before Group Loads",
    value: RuleGroupKind.Pre,
  },
  {
    label: "After Group Submission",
    value: RuleGroupKind.Post,
  },
];

type Props = {
  ruleGroups: RuleGroupEntity[];
  container?: boolean;
} & ({ kind: "questionGroup" } | { kind: "question" | "optionValue"; ownerId: string });

export const EntityRuleGroups = ({ ruleGroups, container, ...props }: Props) => {
  const [active, setActive] = useReducer(
    (_: RuleGroupKind, c: RuleGroupKind) => c,
    props.kind === "optionValue"
      ? RuleGroupKind.Visibility
      : props.kind === "question"
        ? container
          ? RuleGroupKind.Visibility
          : RuleGroupKind.Validation
        : RuleGroupKind.Pre,
  );

  const { insertRuleGroup } = useBuilderNGN();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEnum, setSelectedEnum] = useState<CommonValidationEnum>();
  const [inputParamsData, setInputParamsData] = useState<ValidationData | null>(null);

  const handleInputChange = (data: ValidationData) => {
    setInputParamsData(data);
  };

  const handleModalSubmit = () => {
    if (inputParamsData && props.kind === "question") {
      const valueOfEnumArr = Object.values(CommonValidationEnum);
      const valueOfEnum = valueOfEnumArr.find(item => item === selectedEnum);
      if (valueOfEnum) {
        const group = resolveValidationGroup(props.ownerId, valueOfEnum, inputParamsData);
        insertRuleGroup(props.kind, active, props.ownerId, group);
        setIsModalOpen(false);
      }
    }
  };

  const handleInsertRuleGroup = useCallback(
    (kind: RuleGroupOwner, selectedValue: string) => {
      const valueOfEnumArr = Object.values(CommonValidationEnum);
      const valueOfEnum = valueOfEnumArr.find(item => item === selectedValue);

      setSelectedEnum(valueOfEnum);

      if (valueOfEnum && valueOfEnum !== CommonValidationEnum.DefineNew) {
        if (props.kind === "question") {
          if (Object.values(modalCommonValidations).includes(valueOfEnum)) {
            setIsModalOpen(true);

            switch (valueOfEnum) {
              case CommonValidationEnum.Date:
                setInputParamsData({
                  name: CommonValidationEnum.Date,
                  params: {
                    amount: 1,
                    unit: "day",
                    selectType: "",
                    selectWhenFrom: "",
                    selectWhenTo: "",
                    amountFrom: 1,
                    unitFrom: "",
                    amountTo: 1,
                    unitTo: "",
                  },
                } as DateData);
                break;
              case CommonValidationEnum.Number:
                setInputParamsData({
                  name: CommonValidationEnum.Number,
                  params: {
                    min: 1,
                    max: 1,
                    length: 1,
                  },
                } as NumberData);
                break;

              case CommonValidationEnum.String:
                setInputParamsData({
                  name: CommonValidationEnum.String,
                  params: {
                    min: 1,
                    max: 1,
                    length: 1,
                  },
                } as StringData);
                break;
            }
            return;
          }

          const group = resolveValidationGroup(props.ownerId, valueOfEnum);
          return insertRuleGroup(kind, active, props.ownerId, group);
        }
      }

      insertRuleGroup(kind, active, props.kind !== "questionGroup" ? props.ownerId : undefined);
    },
    [active, insertRuleGroup, props],
  );

  const getSuggestions = () => {
    const existingSuggestions = Object.values(CommonValidationEnum)
      .map(value => ({ label: value, value }))
      .filter(item => ruleGroups.every(r => r.name !== item.value));

    const modalCommonValidationsArr = Object.values(modalCommonValidations).map(value => ({ label: value, value }));

    return Array.from(
      new Map([...existingSuggestions, ...modalCommonValidationsArr].map(item => [item.value, item])).values(),
    );
  };

  const getLabelByValue = (value: RuleGroupKind) => {
    const allGroups = [...QUESTION_RULE_GROUPS, ...GROUP_RULE_GROUPS];
    return allGroups.find(group => group.value === value)?.label || "";
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {inputParamsData ? (
          <ValidationArguments data={inputParamsData} onChange={handleInputChange} onClick={handleModalSubmit} />
        ) : (
          <>Please select a valid validation type.</>
        )}
      </Modal>

      <div className={"flex items-end gap-4 py-3"}>
        <label className={"flex items-center gap-2"}>
          <div className={""}>Rule Group:</div>
          <div>
            <SelectAutocomplete
              suggestions={
                props.kind === "questionGroup"
                  ? GROUP_RULE_GROUPS
                  : props.kind === "optionValue"
                    ? GROUP_RULE_GROUPS.filter(k => k.value === RuleGroupKind.Visibility)
                    : QUESTION_RULE_GROUPS
              }
              onSelect={selectedValue => {
                if (selectedValue) {
                  setActive(selectedValue as RuleGroupKind);
                }
              }}
              value={getLabelByValue(active)}
              placeholder={"Select Rule Group"}
              styles="justify-between border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500 border"
            />
          </div>
        </label>

        {active === RuleGroupKind.Validation ? (
          <label className={"flex items-center gap-2"}>
            <div className={""}>Validation:</div>
            <div>
              <SelectAutocomplete
                suggestions={getSuggestions()}
                onSelect={selectedValue => {
                  if (selectedValue) {
                    handleInsertRuleGroup(props.kind, selectedValue);
                  }
                }}
                value={"Select Validation"}
                placeholder={"Select Validation"}
                styles="justify-between border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500 border"
              />
            </div>
          </label>
        ) : (
          <button
            className={"flex items-center gap-2 px-5 py-[2px] bg-gray-800 text-white rounded"}
            onClick={cb(
              insertRuleGroup,
              props.kind,
              active,
              props.kind !== "questionGroup" ? props.ownerId : undefined,
            )}
          >
            Add Rules Set <AddIcon />
          </button>
        )}
      </div>
      <div>
        {ruleGroups.filter(k => k.kind === active).length ? (
          <RuleGroups
            groups={ruleGroups.filter(k => k.kind === active)}
            kind={active}
            groupType={props.kind}
            ownerId={props.kind !== "questionGroup" ? props.ownerId : undefined}
          />
        ) : (
          <NoData message={"There are no rules associated with this group."} />
        )}
      </div>
    </div>
  );
};

export type DiffEntry = {
  path: string;
  oldValue: any;
  newValue: any;
  header?: string; // Summarization key value
  arrayDiffs?: ArrayDiff[]; // Add this field
};
type ArrayDiffType = "added" | "removed" | "modified";

interface ArrayDiff {
  type: ArrayDiffType;
  value: any;
  index?: number;
}


function sortArrayByType(arr: any[]): any[] {
  if (arr.length === 0) return arr;

  // If array contains objects, sort by stringified version
  if (typeof arr[0] === "object" && arr[0] !== null) {
    return [...arr].sort((a, b) =>
      JSON.stringify(a) < JSON.stringify(b) ? -1 : 1,
    );
  }

  // For primitive types, use regular sort
  return [...arr].sort();
}

interface GroupedDiffResults {
  [key: string]: DiffEntry[];
}

export function groupDiffResults(results: DiffEntry[]): GroupedDiffResults {
  return results.reduce((acc: GroupedDiffResults, curr) => {
    const key = curr.header || "Other Changes";
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {});
}

export function compareJson(
  base: any,
  updated: any,
  path = "",
  results: DiffEntry[] = [],
  summarizationKey = "",
  header?: string,
  isTopLevel: boolean = true,
): DiffEntry[] {

  if (Array.isArray(base) && Array.isArray(updated)) {
    const sortedBase = sortArrayByType(base);
    const sortedUpdated = sortArrayByType(updated);

    if (sortedBase.length > 0 && typeof sortedBase[0] === "object" && sortedBase[0] !== null) {
      sortedBase.forEach((baseItem, index) => {
        const updatedItem = sortedUpdated.find(item =>
          item[summarizationKey] === baseItem[summarizationKey],
        );

        const currentHeader = isTopLevel
          ? baseItem[summarizationKey]
          : header;

        if (updatedItem) {
          const beforeResultsLength = results.length;
          compareJson(
            baseItem,
            updatedItem,
            `${path}[${index}]`,
            results,
            summarizationKey,
            currentHeader,
            false,
          );
          if (results.length === beforeResultsLength) {
            return;
          }
        } else {
          results.push({
            path: `${path}[${index}]`,
            oldValue: baseItem,
            newValue: undefined,
            header: currentHeader,
          });
        }
      });

      sortedUpdated.forEach((updatedItem, index) => {
        const exists = sortedBase.some(item =>
          item[summarizationKey] === updatedItem[summarizationKey],
        );

        if (!exists) {
          results.push({
            path: `${path}[${index}]`,
            oldValue: undefined,
            newValue: updatedItem,
            header: isTopLevel
              ? updatedItem[summarizationKey]
              : header,
          });
        }
      });

      return results;
    }

    return results;
  }

  if (typeof base === "object" && base !== null &&
    typeof updated === "object" && updated !== null) {
    const allKeys = new Set([...Object.keys(base), ...Object.keys(updated)]);

    allKeys.forEach(key => {
      const newPath = path ? `${path}.${key}` : key;

      if (!(key in base) && !isEmpty(updated[key])) {
        results.push({
          path: newPath,
          oldValue: undefined,
          newValue: updated[key],
          header,
        });
      } else if (!(key in updated) && !isEmpty(base[key])) {
        results.push({
          path: newPath,
          oldValue: base[key],
          newValue: undefined,
          header,
        });
      } else if (JSON.stringify(base[key]) !== JSON.stringify(updated[key])) {
        compareJson(
          base[key],
          updated[key],
          newPath,
          results,
          summarizationKey,
          header,
          false,
        );
      }
    });

    return results;
  }

  if (!compareTheTwo(base, updated)) {
    results.push({
      path,
      oldValue: base,
      newValue: updated,
      header,
    });
  }

  return results;
}

function isEmpty(val: any) {
  return val === undefined || val === null ||
    (Array.isArray(val) && val.length === 0) ||
    (typeof val === "object" && val !== null && Object.keys(val).length === 0);
}

function compareTheTwo(a: any, b: any): boolean {
  if (a === b) return true;


  return isEmpty(a) && isEmpty(b);
}
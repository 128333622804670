import { createContext, useContext, useEffect, useState } from "react";
import useAsyncFn from "../utils/useAsyncFn";
import { getAvailableAgencies, getBusinessReports, getQuoteDetails } from "../http/app";
import { QuestionnaireState } from "../types/customer-record";
import { LoadingScreen } from "../components/shared/LoadingScreen";

type BusinessReportsState = {
  availableAgencies: any[];
  businessReports: any[];
  quoteDetails: QuestionnaireState | null;
};
const BusinessReportsStateCtx = createContext<{
  state: BusinessReportsState;
  getAvailableAgenciesR: () => void;
  getBusinessReportsR: (tenantId: string, from: string, to: string, branchId?: string, userId?: string) => void;
  getQuoteDetailsR: (quoteId: string) => void;
  clearQuoteDetails: () => void;
}>({} as never);


export const useBusinessReportsState = () => useContext(BusinessReportsStateCtx);

export const BusinessReportsStateProvider = ({ children }: { children: React.ReactNode }) => {
  const { exec: getAvailableAgenciesR, data: availableAgenciesResponse, loading } = useAsyncFn(getAvailableAgencies);
  const { exec: getBusinessReportsR, data: businessReportsResponse, loading: loadingBusinessReports } = useAsyncFn(getBusinessReports);
  const { exec: getQuoteDetailsR, data: quoteDetailsResponse, loading: loadingQuoteDetails } = useAsyncFn(getQuoteDetails);

  const [state, setState] = useState<BusinessReportsState>({
    availableAgencies: [],
    businessReports: [],
    quoteDetails: null,
  });

  const clearQuoteDetails = () => {
    setState((prevState) => ({ ...prevState, quoteDetails: null }));
  };

  useEffect(() => {
    if (availableAgenciesResponse) {
      console.log(availableAgenciesResponse);
      setState((prevState) => ({
        ...prevState,
        availableAgencies: availableAgenciesResponse.ok ? availableAgenciesResponse.data.data : [],
      }));
    }
  }, [availableAgenciesResponse]);

  useEffect(() => {
    if (businessReportsResponse) {
      setState((prevState) => ({
        ...prevState,
        businessReports: businessReportsResponse.ok ? businessReportsResponse.data : [],
      }));
    }
  }, [businessReportsResponse]);

  useEffect(() => {
    console.log(quoteDetailsResponse);
    if (quoteDetailsResponse) {
      setState((prevState) => ({
        ...prevState,
        quoteDetails: quoteDetailsResponse.ok ? quoteDetailsResponse.data : null,
      }));
    }
  }, [quoteDetailsResponse]);


  return <BusinessReportsStateCtx.Provider
      value={{ state, getAvailableAgenciesR, getBusinessReportsR, getQuoteDetailsR, clearQuoteDetails }}>
        {(loading || loadingBusinessReports || loadingQuoteDetails) && (
                <LoadingScreen message="Loading Business Reports" />
            )}
     {children}
  </BusinessReportsStateCtx.Provider>;
};

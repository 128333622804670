import { Answers } from "@Savus-Inc/questionnaire-types";

export enum QuestionnaireSubmissionStatus {
  Started = "Started",
  RequestTimeout = "RequestTimeout",
  Errored = "Errored",
  Completed = "Completed",
  Declined = "Declined",
  SubjectToReferral = "SubjectToReferral",
  HasNoAppetiteToQuote = "HasNoAppetiteToQuotes",
  CustomerNotCleared = "CustomerNotCleared"
}

export interface QuestionnaireState {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  applicationId: string;
  customerRecordId: string;
  answers: Answers;
  page: number;
  submissions: QuoteSubmission[];
  userId: string;
  agencyBranchId: string | null;
}

export interface QuestionnaireCarrierProduct {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  questionnaireId: string;
  questionnaireStateId: string;
  lineOfBusinessId: string;
  carrierId: string;
}

export interface QuestionnaireSubmissionMeta {
  data: any; // Raw carrier response JSON
  createdAt: string;
  status: string;
}

export interface QuoteSubmission {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  questionnaireStateId: string;
  questionnaireCarrierProductId: string;
  carrierId: string;
  status: QuestionnaireSubmissionStatus;
  coverage: Coverage | null;
  coverageId: string | null;
  rc1Received: string | null;
  submissionUpdated: string | null;
  questionnaireCarrierProduct: QuestionnaireCarrierProduct;
  questionnaireSubmissionMeta: QuestionnaireSubmissionMeta | null;
}

export interface Address {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  address: string;
  suite: string | null;
  city: string;
  stateId: string;
  state: {
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    name: string;
    shortName: string;
    zipcode: string;
    timezone: string;
    fullName: string;
  };
  zip: string;
}

export type CustomerRecord = {
  id: string;
  applicationId?: string;
  quoteStateId?: string;
  businessName: string;
  emailAddress: string;
  phoneNumber: string;
  firstName: string;
  businessTypeId?: string;
  lastName: string;
  address: Address;
  businessType: {
    id: string;
    title: string;
    naicsCode: string;
    naicsTitle: string;
    classCode?: string;
  };
  progress: {
    page: number;
    answers: Answers;
  };
  agencyBranch: {
    id: string;
    name: string;
  },
  user: {
    id: string;
    username: string;
  }
  fcraStatement: boolean;
  marker?: string
  updatedAt?: string
  createdAt?: string;
  deletedAt?: string | null;
  middleInitial?: string | null;
  suffix?: string | null;
  phoneExt?: string | null;
  fkaName?: string | null;
  mailingAddress?: Address | null;
  agencyId?: string;
  questionnaireState?: QuestionnaireState[];
};

export interface CoverageLimit {
  limit: string;
  value: number;
  name?: string;
}

export interface Building {
  [buildingId: string]: CoverageLimit[];
}

export interface Location {
  locationId: string;
  propertyDeductible?: number;
  locationType?: string;
  name?: string;
  address1: string;
  city: string;
  zipCode: string;
  state: string;
  buildings?: Building[];
}

export interface CoverageDetails {
  policyUrl?: string;
  main?: CoverageLimit[];
  locations?: Location[];
}

export interface Coverage {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  externalId: string;
  externalProposalId: string | null;
  productName: string;
  totalAmount: number;
  details: CoverageDetails;
  questionnaireSubmissionId: string;
  coverageUpdated: string | null;
}

export interface QuestionnaireSubmission {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  questionnaireStateId: string;
  questionnaireCarrierProductId: string;
  questionnaireCarrierProduct: QuestionnaireCarrierProduct;
  carrierId: string;
  status: string;
  coverage: Coverage | null;
  coverageId: string | null;
  rc1Received: string | null;
  questionnaireSubmissionMeta: QuestionnaireSubmissionMeta | null;
  submissionUpdated: string | null;
}

import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { parseFileContent, flattenObject } from "../utils/json-utils";

interface ColumnConfig {
  originalKey: string;
  displayName: string;
  include: boolean;
  order: number;
}

interface JsonAnalysis {
  totalRecords: number;
  uniqueKeys: string[];
  sampleValues: Record<string, string>;
}

interface JsonToCsvProps {
  className?: string;
}

export const JsonToCsv: React.FC<JsonToCsvProps> = ({ className }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [entryPoint, setEntryPoint] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [jsonData, setJsonData] = useState<any[] | null>(null);
  const [analysis, setAnalysis] = useState<JsonAnalysis | null>(null);
  const [columnConfig, setColumnConfig] = useState<ColumnConfig[]>([]);
  const [step, setStep] = useState<'upload' | 'analyze' | 'configure'>('upload');
  const [searchKey, setSearchKey] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const analyzeJson = async (data: any[]) => {
    setIsProcessing(true);
    try {
      const flattenedData = data.flatMap(item => flattenObject(item));
      const uniqueKeys = Array.from(
        new Set(flattenedData.flatMap(obj => Object.keys(obj)))
      ).sort();

      // Get sample values for each key
      const sampleValues: Record<string, string> = {};
      uniqueKeys.forEach(key => {
        const value = flattenedData.find(obj => obj[key] !== undefined)?.[key];
        sampleValues[key] = value ? String(value).substring(0, 50) : '';
      });

      setAnalysis({
        totalRecords: data.length,
        uniqueKeys,
        sampleValues
      });

      setColumnConfig(uniqueKeys.map((key, index) => ({
        originalKey: key,
        displayName: key,
        include: true,
        order: index
      })));

      setStep('analyze');
    } catch (err) {
      setError(err instanceof Error ? err.message : "Error analyzing JSON");
    }
    setIsProcessing(false);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setError(null);
      
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const fileContent = e.target?.result as string;
          let data = parseFileContent(fileContent);

          if (entryPoint) {
            const keys = entryPoint.split(".");
            for (const key of keys) {
              data = data[key];
              if (!data) {
                throw new Error(`Entry point "${entryPoint}" not found in JSON`);
              }
            }
          }

          const dataArray = Array.isArray(data) ? data : [data];
          setJsonData(dataArray);
          await analyzeJson(dataArray);
        } catch (err) {
          setError(err instanceof Error ? err.message : "An error occurred");
        }
      };

      reader.onerror = () => {
        setError("Error reading file");
      };

      reader.readAsText(event.target.files[0]);
    }
  };

  const handleExport = () => {
    if (!jsonData) return;

    try {
      const flattenedData = jsonData.flatMap(item => flattenObject(item));
      
      // Sort and filter columns based on configuration
      const sortedConfig = [...columnConfig]
        .filter(col => col.include)
        .sort((a, b) => a.order - b.order);

      const csv = Papa.unparse({
        fields: sortedConfig.map(col => col.displayName),
        data: flattenedData.map(row => {
          const newRow: Record<string, string> = {};
          sortedConfig.forEach(col => {
            newRow[col.displayName] = row[col.originalKey] || '';
          });
          return newRow;
        })
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${selectedFile?.name.replace(".json", "")}-${new Date().getTime()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Error exporting CSV");
    }
  };

  const filteredColumns = columnConfig.filter(col => 
    col.originalKey.toLowerCase().includes(searchKey.toLowerCase())
  );

  const moveColumn = (index: number, direction: 'up' | 'down') => {
    const newConfig = [...columnConfig];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    
    if (newIndex >= 0 && newIndex < newConfig.length) {
      [newConfig[index].order, newConfig[newIndex].order] = 
      [newConfig[newIndex].order, newConfig[index].order];
      
      setColumnConfig(newConfig.sort((a, b) => a.order - b.order));
    }
  };

  return (
    <div className={`border rounded-lg p-4 ${className}`}>
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold">JSON to CSV Converter</h2>
        {step !== 'upload' && (
          <button
            className="text-sm text-blue-600 hover:text-blue-800"
            onClick={() => setStep('upload')}
          >
            ← Start Over
          </button>
        )}
      </div>

      {step === 'upload' && (
        <div className="space-y-6">
          <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
            <input
              type="file"
              accept=".json"
              onChange={handleFileChange}
              className="hidden"
              id="fileInput"
            />
            <label
              htmlFor="fileInput"
              className="cursor-pointer flex flex-col items-center space-y-2"
            >
              <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <span className="text-gray-600">Drop your JSON file here or click to browse</span>
            </label>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm text-gray-600">Entry Point (optional)</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={entryPoint}
              onChange={e => setEntryPoint(e.target.value)}
              placeholder="e.g., data.items"
            />
          </div>
        </div>
      )}

      {step === 'analyze' && analysis && (
        <div className="space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-4">JSON Analysis</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-600">Total Records</p>
                <p className="text-xl font-semibold">{analysis.totalRecords}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Unique Fields</p>
                <p className="text-xl font-semibold">{analysis.uniqueKeys.length}</p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              onClick={() => setStep('upload')}
            >
              Back
            </button>
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              onClick={() => setStep('configure')}
            >
              Configure Columns
            </button>
          </div>
        </div>
      )}

      {step === 'configure' && (
        <div className="space-y-6">
          <div className="flex items-center space-x-4 mb-4">
            <input
              type="text"
              placeholder="Search fields..."
              className="flex-1 p-2 border rounded"
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
            />
            <button
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
              onClick={handleExport}
            >
              Export CSV
            </button>
          </div>

          <div className="border rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Include</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Original Field</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Display Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Sample Value</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Order</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredColumns.map((col, index) => (
                  <tr key={col.originalKey}>
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={col.include}
                        onChange={e => {
                          const newConfig = [...columnConfig];
                          newConfig[index].include = e.target.checked;
                          setColumnConfig(newConfig);
                        }}
                      />
                    </td>
                    <td className="px-6 py-4">{col.originalKey}</td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        className="w-full p-1 border rounded"
                        value={col.displayName}
                        onChange={e => {
                          const newConfig = [...columnConfig];
                          newConfig[index].displayName = e.target.value;
                          setColumnConfig(newConfig);
                        }}
                      />
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {analysis?.sampleValues[col.originalKey]}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center space-x-2">
                        <button
                          className="p-1 text-gray-400 hover:text-gray-600"
                          onClick={() => moveColumn(index, 'up')}
                          disabled={index === 0}
                        >
                          ↑
                        </button>
                        <button
                          className="p-1 text-gray-400 hover:text-gray-600"
                          onClick={() => moveColumn(index, 'down')}
                          disabled={index === columnConfig.length - 1}
                        >
                          ↓
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {error && (
        <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}

      {isProcessing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-2 text-gray-600">Processing...</p>
          </div>
        </div>
      )}
    </div>
  );
}; 
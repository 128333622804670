import Papa from "papaparse";
import {
  Question,
  Questionnaire,
  Questionnaire as Q,
  QuestionnaireGroupSection,
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionGroupEntity,
  QuestionValidityAction,
  QuestionValueAutocompleteIntegration,
  QuestionValueOption,
  QuestionVisibility,
  RuleGroupEntity,
  RuleGroupKind,
  ValidationAlertLevel,
} from "@Savus-Inc/questionnaire-types";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { createQuestionnaire, questionnaireBulkInsert, removeQuestion } from "../../../http/questionnaire";
import React, { useMemo, useReducer } from "react";
import { useBuilderNGN } from "../BuilderNGN";
import { ruleGroupToString, stringToOp, stringToRuleGroup } from "@Savus-Inc/dsl/dist/utils/transformer";
import { useAppState } from "../../../state/State";
import { getInputTypeAndQuestionKind } from "./get-input-type-and-question-kind";
import { getQuestionFormat } from "./get-question-format";
import { processVisibilityGroup } from "./process-visibility-group";
import { calculateQuestionExternalIdMap } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { overrideOpReference } from "../UploadMapping";
import { ComplexOperator, ValueFunctions } from "@Savus-Inc/dsl/dist/types";

const carrierNames = [
  "Cna",
  "NationWide",
  "LibertyMutual",
  "Travelers",
  "Chubb",
  "Markel",
  "Employers",
  "AmTrust",
];

const UploadCSV: React.FC = () => {
  const navigate = useNavigate();
  const { item: currentQuestionnaire, questions: currentQuestions } = useBuilderNGN();

  const {
    state: { carriers },
  } = useAppState();

  const [message, setMessage] = useReducer((_: string, v: string) => v, "");

  const product = useMemo(() => {
    if (!currentQuestionnaire) return;

    if (currentQuestionnaire.lineOfBusiness?.shortName === "GLI") {
      return "GL";
    }

    return currentQuestionnaire.lineOfBusiness?.shortName;
  }, [currentQuestionnaire]);

  const bulkUpload = async (q: Questionnaire) => {
    for (const group of q.groups) {
      setMessage(`Updating page (P-${group.partOrder}) ${group.title}`);
      group.questions.forEach(q => {
        q.externalId = q.question?.externalId;
      });

      const map = {
        ...calculateQuestionExternalIdMap(q), ...(product ? carrierNames.reduce((acc, v) => ({
          ...acc,
          [`ClassCode${v}`]: `ClassCode${v}${product}`,
        }), {}) : {}),
      };
      const groupData = {
        ...group,
        questions: group.questions.map(q => {
          const parent = group.questions.find(pq => pq.id === q.parentId);

          const question: QuestionnaireQuestionEntity = {
            ...q,
            sectionId: parent ? null as never : q.sectionId,
            showForCarriers: parent ? q.showForCarriers.filter(v => parent.showForCarriers.includes(v)) : q.showForCarriers,
            ruleGroups: q.ruleGroups.map((rg, i) => ({ ...rg, partOrder: i })),
            question: {
              ...q.question,
              values: q.question?.values.map(q => {
                if (q.visibility?.length) {
                  q.visibility.forEach(rg => {
                    rg.rules.forEach(r => {
                      overrideOpReference(r.value, map);
                    });
                  });
                }

                if (q.label.includes("{")) {
                  let label = q.label;
                  let value = q.value.value;

                  Object.entries(map.map).forEach(([k, v]) => {
                    label = label.replace(k, v as string);
                  });

                  if (typeof q.value?.value === "string" && q.value.value.includes("{")) {
                    Object.entries(map.map).forEach(([k, v]) => {
                      value = label.replace(k, v as string);
                    });
                  }

                  return {
                    ...q,
                    label,
                    value: {
                      ...q.value,
                      value,
                    },
                  } as QuestionValueOption;
                }

                return q;
              }) || [],
            } as Question,
          };

          question.ruleGroups.forEach(rg => {
            rg.rules = rg.rules.map(r => {
              try {
                overrideOpReference(r.value, map);
                return r;
              } catch (e) {
                console.log(question.question?.externalId, rg);
                return false as never;
              }
            }).filter(Boolean);
          });

          const getCarrierName = (c: string) => {
            if (c === "Nationwide") {
              return "NationWide";
            }

            if (c.startsWith("Liberty")) {
              return "LibertyMutual";
            }

            return c;
          };

          const carriersWithoutDisplay = q.showForCarriers.length === 1 ? [] : q.showForCarriers.filter(c => {
            const visibility: RuleGroupEntity<QuestionVisibility>[] = q.ruleGroups.filter(rg => rg.kind === RuleGroupKind.Visibility);
            if (visibility.length) {
              const hasCarrierSpecificDR = q.showForCarriers.filter(v => v !== c).some(c => visibility.some(rg => ruleGroupToString(rg).toLowerCase().includes(getCarrierName(c).toLowerCase())));
              const doesNotHaveOwnDisplayRules = visibility.every(rg => !ruleGroupToString(rg).toLowerCase().includes(getCarrierName(c).toLowerCase()));


              if (hasCarrierSpecificDR && doesNotHaveOwnDisplayRules) {
                console.log("Does not have own display rules", question.externalId, visibility);
              }
              return hasCarrierSpecificDR && doesNotHaveOwnDisplayRules;
            }
            return false;
          });

          if (carriersWithoutDisplay.length) {
            console.log("Detected carriers without display", q.externalId, carriersWithoutDisplay);
            question.ruleGroups.push({
              id: v4(),
              not: false,
              name: "Show if carrier is active",
              actionKind: QuestionVisibility.Show,
              kind: RuleGroupKind.Visibility,
              op: ComplexOperator.Standalone,
              partOrder: question.ruleGroups.length,
              rules: [{
                id: v4(),
                op: ComplexOperator.Standalone,
                value: {
                  fn: ValueFunctions.In,
                  args: [{ ref: "Carrier" }, ...carriersWithoutDisplay],
                },
              }],
            } as never);
          }

          return question;
        }),
      };

      const batchedQuestions = [];
      const batchSize = 100;

      const questions = groupData.questions.reduce((p, c) => {
        if (c.parentId) {
          p.children.push(c);
        } else {
          p.parents.push(c);
        }

        return p;
      }, { children: [], parents: [] } as Record<"children" | "parents", QuestionnaireQuestionEntity[]>);

      const questionsToInsert = [...questions.parents, ...questions.children];

      for (let i = 0; i < questionsToInsert.length; i += batchSize) {
        batchedQuestions.push(questionsToInsert.slice(i, i + batchSize));
      }

      for (let i = 0; i < batchedQuestions.length; i++) {
        setMessage(
          `Updating batch ${i + 1} of ${batchedQuestions.length} for page (P-${group.partOrder}) ${group.title}`,
        );

        await questionnaireBulkInsert(q.id, {
          ...q,
          groups: [
            {
              ...groupData,
              questions: batchedQuestions[i],
            },
          ],
        } as Q);
      }
    }
  };
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (currentQuestionnaire && !currentQuestionnaire?.lineOfBusinessId) {
      alert("Please select a product for questionnaire before the update.");
      window.location.reload();
      return;
    }

    setMessage("Preparing file...");

    if (file?.type?.toLowerCase()?.includes("json")) {
      const q: Questionnaire = JSON.parse(await file?.text());
      setMessage("Creating questionnaire...");

      const base = {
        id: q.id,
        title: q.title,
        name: q.name,
        subText: q.subText,
      };
      createQuestionnaire(base).then(async () => {
        await bulkUpload(q);
        navigate(base.id);
      });

      return;
    }

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async results => {
          const isInQuestionnaireContext = !!currentQuestionnaire;

          const map = Object.fromEntries(
            (isInQuestionnaireContext ? currentQuestions : []).map(v => [v.question?.externalId, v.id]),
          );


          setMessage("Converting data...");
          const csvData = results.data as any[];

          const questions: Record<string, QuestionnaireQuestionEntity> = {};
          const groups: Record<string, QuestionnaireQuestionGroupEntity> = {};
          const sections: Record<string, QuestionnaireGroupSection & { groupId: string }> = {};

          let base: any;
          const deleted = new Set<string>();

          const existingQuestions = currentQuestions.map(q => q.question?.externalId);

          for (const item of csvData) {
            if (
              !item.questionId ||
              (!!currentQuestionnaire && !item.product?.toLowerCase()?.includes(product?.toLowerCase()))
            )
              continue;

            if (!isInQuestionnaireContext && item.delete) {
              deleted.add(item.questionId);
              continue;
            }

            if (deleted.has(item.questionId)) {
              continue;
            }


            if (item.deleted && isInQuestionnaireContext) {
              const question = currentQuestions.find(q => q.question?.externalId === item.questionId);
              if (question) {
                await removeQuestion(currentQuestionnaire.id, question.groupId, question.id);
                deleted.add(item.questionId);
              }

              continue;
            }

            if (isInQuestionnaireContext) {
              if (existingQuestions.includes(item.questionId)) {
                console.log("OLD Q", item.questionId);
              }
              console.log("NEW Q", item.questionId);
            }


            if (!base)
              base = {
                title: `Questionnaire ${item?.product}`,
                name: `Questionnaire ${item?.product}`,
                subText: csvData[0]?.subText,
                id: isInQuestionnaireContext ? currentQuestionnaire.id : v4(),
              };

            if (!groups[item.pageId]) {
              const page = currentQuestionnaire?.groups.find(g => {
                return g.partOrder === +(item.pageOrder as string);
              }) as QuestionnaireQuestionGroupEntity | undefined;

              groups[item.pageId] = {
                questionnaireId: base.id,
                ruleGroups: [],
                id: page?.id || v4(),
                title: page?.title || item.pageTitle,
                subText: page?.subText || item.pageSubText,
                partOrder: item.pageOrder,
                questions: [],
                sections: page?.sections || [],
                displaySections: page?.displaySections || item.pageDisplay,
              };
            }

            const sectionId = Object.values(sections).find(s => s.groupId === item.pageId && s.title?.toLowerCase().trim().replace(" ", "") === item.sectionTitle.toLowerCase().trim().replace(" ", ""))?.id || v4();

            if (!sections[sectionId]) {
              const section = currentQuestionnaire?.groups
                .flatMap(g => g.sections)
                .find(s => s?.title?.toLowerCase().trim().replace(" ", "") === item.sectionTitle.toLowerCase().trim().replace(" ", ""));

              sections[sectionId] = {
                display: item.sectionDisplay,
                id: section?.id || sectionId,
                partOrder: item.sectionOrder,
                questions: [item.questionId],
                groupId: item.pageId,
                title: item.sectionTitle,
              };
            } else if (sectionId && !sections[sectionId].questions.includes(item.questionId)) {
              sections[sectionId].questions.push(item.questionId);
            }

            const carrierClassCodes = carrierNames.map(c => `ClassCode${c}`);
            const fieldMap = {
              ...map, ...(item.product ? carrierClassCodes.reduce((acc, v) => ({
                ...acc,
                [v]: `${v}${item.product}`,
              }), {}) : {}),
            };

            let newQuestion = !questions[item.questionId];
            if (newQuestion) {
              let qId: string;
              if (fieldMap[item.questionId]) {
                qId = fieldMap[item.questionId];
              } else {
                qId = v4();
                fieldMap[item.questionId] = qId;
              }


              const question: QuestionnaireQuestionEntity = {
                parentId: item.questionContainerId || undefined,
                mappedFrom: item.mappedFrom || undefined,
                sectionId: sectionId ? sections[sectionId]?.id : Object.values(sections)[0]?.id,
                id: qId,
                partOrder: +item.questionOrder,
                ruleGroups: [],
                questionnaireId: base.id,
                questionId: qId,
                showForCarriers: carriers
                  .filter(c => item?.carrier?.toLowerCase().includes(c.name.toLowerCase()))
                  .map(c => c.name),
                question: {
                  id: qId,
                  externalId: item.questionId,
                  autocompleteIntegration: item.autocompleteIntegration
                    ? item.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues ||
                    item.autocompleteIntegration === "GoogleApi"
                      ? QuestionValueAutocompleteIntegration.OwnValues
                      : QuestionValueAutocompleteIntegration.GoogleLocation
                    : null,
                  values: [],
                  format: getQuestionFormat(item.questionFormat),
                  label: item.fieldLabel,
                  placeholder: item.fieldPlaceholder,
                  subText: item.fieldSubText,
                  tooltip: item.fieldTooltip,
                  subQuestions: item.subQuestionDisplay || undefined,
                  maxAnswers: item.maxAnswers || undefined,
                  minAnswers: item.minAnswers || undefined,
                  answerDisplay: item.answerDisplay || undefined,
                  multipleAnswers: item.fieldMultipleAnswers?.toLowerCase() === "true",
                  bulletList: item.bulletList?.split(";") || undefined,
                  ...getInputTypeAndQuestionKind(item),
                } as Question,
                groupId: item.pageId,

              } as unknown as QuestionnaireQuestionEntity;

              questions[item.questionId] = question;
            } else if (
              !questions[item.questionId].showForCarriers.some(
                v => v.toLowerCase().trim() === item.carrier.toLowerCase().trim(),
              )
            ) {
              questions[item.questionId].showForCarriers.push(
                ...carriers.filter(c => item?.carrier?.toLowerCase().includes(c.name.toLowerCase())).map(c => c.name),
              );
            }
            const questionnaireQuestion = questions[item.questionId];
            const question = questionnaireQuestion.question as Question;

            const parent = questionnaireQuestion?.parentId ? questions[questionnaireQuestion.parentId as never] : undefined;

            ["valueVisibility", "validation", "visibility", "prefill"].forEach((v) => {
              if (item[v] && item[v].trim()) {
                item[v] = item[v].trim().replace(/\n/g, "").replace(/\r/g, "").replace(/\t/g, "").replace(/\s+/g, " ");
              }
            });

            if (parent?.mappedFrom) {
              ["valueVisibility", "validation", "visibility", "prefill"].forEach(v => {
                if (item[v] && item[v].trim()) {
                  carrierClassCodes.forEach(c => {
                    item[v] = item[v].replace(`$${c}`, `Pick($Q-2111, $${c})`);
                  });
                }
              });
            }

            if (
              item.autocompleteIntegration &&
              item.answerLabel &&
              !questions[item.questionId]?.question?.values.some(v => v.label === item.answerLabel)
            ) {
              const visibility: RuleGroupEntity[] = [];
              if (item.valueVisibility?.trim()) {
                const groups = item.valueVisibility
                  .split(";")
                  .map((v: string) => v.trim())
                  .filter(Boolean)
                  .map((gr: string, i: number) => {
                    return processVisibilityGroup(
                      gr,
                      fieldMap,
                      i,
                    );
                  });

                visibility.push(...groups);
              }

              question.values.push({
                id: item.ValueId || item.valueId,
                partOrder: Number.isNaN(+item.answerOrder) ? 0 : +item.answerOrder,
                value: { value: item.answerLabel } as never,
                questionId: questions[item.questionId].id,
                label: item.answerLabel,
                visibility,
              });
            }

            if (newQuestion && item.validation?.trim()) {
              const groups = item.validation
                .split(";")
                .map((v: string) => v.trim())
                .filter(Boolean)
                .map((gr: string, i: number) => {
                  const [group, action] = gr.split("--").map((v: string) => v.trim());

                  const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
                  return {
                    ...ruleGroup,
                    name: gr,
                    kind: RuleGroupKind.Validation,
                    partOrder: i,
                    actionKind: {
                      message: action.replace(/Error|Warning|\(|\)/g, ""),
                      alertLevel: action.startsWith("Error")
                        ? ValidationAlertLevel.Error
                        : ValidationAlertLevel.Warning,
                    } as QuestionValidityAction,
                  };
                });

              if (groups && groups.length > 0) {
                questions[item.questionId].ruleGroups.push(...groups);
              }
            } else if (newQuestion && item.Required) {
              const ruleGroup = stringToRuleGroup(`Unanswered($${fieldMap[item.questionId]}, True)`)[0];
              questions[item.questionId].ruleGroups.push({
                ...ruleGroup,
                id: v4(),
                name: `Unanswered($${questions[item.questionId].id}, True)`,
                kind: RuleGroupKind.Validation,
                partOrder: questions[item.questionId].ruleGroups.length,
                actionKind: {
                  message: "Required",
                  alertLevel: ValidationAlertLevel.Error,
                } as QuestionValidityAction,
              } as never);
            }
            if (item.visibility?.trim()) {
              const groups = item.visibility
                .split(";")
                .map((v: string) => v.trim())
                .filter(Boolean)
                .map((gr: string, i: number) => {
                  const [group, action] = gr.split("--").map((v: string) => v.trim());

                  const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
                  return {
                    ...ruleGroup,
                    name: gr,
                    kind: RuleGroupKind.Visibility,
                    partOrder: i,
                    actionKind:
                      action.toLowerCase() === "show"
                        ? QuestionVisibility.Show
                        : action.toLowerCase() === "readonly"
                          ? QuestionVisibility.ReadOnly
                          : QuestionVisibility.Hidden,
                    id: v4(),
                  };
                });

              if (groups && groups.length > 0) {
                questions[item.questionId].ruleGroups.push(...groups);
              }
            }

            if (newQuestion && item.prefill?.trim()) {
              const groups = item.prefill
                .split(";")
                .map((v: string) => v.trim())
                .filter(Boolean)
                .map((gr: string, i: number) => {
                  const [group, action] = gr.split("--").map((v: string) => v.trim());

                  try {
                    const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
                    return {
                      ...ruleGroup,
                      name: gr,
                      partOrder: i,
                      kind: RuleGroupKind.PreFill,
                      actionKind: stringToOp(action, fieldMap),
                      id: v4(),
                    };
                  } catch (e) {
                    console.log("action: ", action, "group: ", group);
                    throw e;
                  }
                });

              if (groups && groups.length > 0) {
                questions[item.questionId].ruleGroups.push(...groups);
              }
            }

            if (parent?.mappedFrom) {
              console.log(questions[item.questionId].ruleGroups);
            }
          }


          Object.values(questions)
            .sort((a, b) => a.partOrder - b.partOrder)
            .forEach(q => {
              q.question?.values.sort((a, b) => a.partOrder - b.partOrder);
              q.parentId = q.parentId ? questions[q.parentId]?.id || map[q.parentId]?.id : undefined;
              const groupId = q.groupId;
              q.groupId = groups[q.groupId].id;
              groups[groupId].questions.push(q);
              q.ruleGroups = q.ruleGroups.filter(rg => !!rg.rules.length && !rg.rules.some(r => !r.value));

            });

          Object.values(sections)
            .sort((a, b) => a.partOrder - b.partOrder)
            .forEach(s => {
              s.questions = s.questions.map(k => questions[k]?.id);
              const sections = groups[s.groupId].sections;
              if (sections) {
                sections.push(s as never);
              }
            });

          const payload = {
            ...base,
            groups: Object.values(groups)
              .sort((a, b) => a.partOrder - b.partOrder)
              .map(gr => ({
                ...gr,
                partOrder: gr.partOrder,
                questions: Object.values(questions)
                  .filter(q => q.groupId === gr.id)
                  .sort((a, b) => a.partOrder - b.partOrder),
              }))
              .filter(gr => !!gr.questions.length),
          };

          console.log("payload", payload);

          if (isInQuestionnaireContext) {
            await bulkUpload(payload);
            window.location.reload();
          } else {
            console.log("creating questionnaire...", base, payload);
            setMessage("Creating questionnaire...");

            createQuestionnaire({
              ...base,
            }).then(async () => {
              await bulkUpload(payload);
              navigate(base.id);
            });
          }
        },
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4 mb-4">
      <div className="w-full max-w-lg flex flex-col justify-center">
        <h2 className="text-2xl  font-semibold mb-4 text-center">Upload CSV or JSON File</h2>
        <input
          type="file"
          accept=".csv,.json"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 bg-white px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          "
        />
        {message && <div className={"text-sky-600 text-center"}>{message}</div>}
      </div>
    </div>
  );
};

export default UploadCSV;

import { CopyAction, ImportAction, RemoveAction } from "../util/ItemActions";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { Rule } from "./Rule";
import { RuleGroupAction } from "./Header/RuleGroupAction";
import { BuilderNGN, RuleGroupOwner, useBuilderNGN } from "../Questionaire/BuilderNGN";
import { cb } from "../../utils/cd";
import { QuestionRuleGroupEntity, RuleGroupEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { ComplexOperatorToggle } from "./ComplexOperatorToggle";
import { ComplexOperator } from "@Savus-Inc/dsl/dist/types";
import classNames from "classnames";
import { NoData } from "../util/Empty";
import React, { useCallback, useMemo, useState } from "react";
import { ruleGroupToString } from "@Savus-Inc/dsl/dist/utils/transformer";
import { RuleEditor } from "../RuleEditor/RuleEditor";
import { getAllRefs } from "./utils/getAvailableRefs";

const SingleRuleGroup = ({ item, updateRuleGroup, groupType, kind, ownerId }: {
  item: RuleGroupEntity,
  updateRuleGroup: BuilderNGN["updateRuleGroup"],
  groupType: RuleGroupOwner,
  ownerId: string | undefined,
  kind: RuleGroupKind
}) => {
  const { context } = useBuilderNGN();
  const [toggleEditor, setToggleEditor] = useState(0);
  const variables = useMemo(() => {
    return getAllRefs(context());
  }, [context]);

  const str = useMemo(() => {
    try {
      return ruleGroupToString(item);
    } catch (e) {
      return "";
    }
  }, [item]);

  const handleEditorChange = useCallback((value: RuleGroupEntity[]) => {
    updateRuleGroup(groupType, item, "rules", ownerId)(value[0].rules);
    setToggleEditor(0);
  }, [groupType, item, ownerId, updateRuleGroup]);


  if (toggleEditor === 0) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 justify-end px-4 pt-2">
          <button onClick={() => setToggleEditor(1)}
                  className="text-sm text-gray-100 bg-teal-500 px-2 py-1 rounded-md w-32">Code Edit
          </button>
          <button onClick={() => setToggleEditor(2)}
                  className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md w-32">Builder Edit
          </button>
        </div>

        <div className="rounded-lg border border-gray-200 bg-teal-50 m-2 p-4 font-mono text-sm relative">
          <div className="absolute top-0 right-2 text-gray-500 text-xs">
            <CopyAction value={str} />
          </div>
          <pre className="whitespace-pre-wrap break-words text-gray-700 leading-relaxed">
            {str || "No rule defined"}
          </pre>
        </div>
      </div>
    );
  }

  if (toggleEditor === 1) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 justify-end px-4 pt-2 transform -translate-x-36">
          <button onClick={() => setToggleEditor(0)}
                  className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md w-32">Close
          </button>
        </div>
        <RuleEditor
          value={str}
          onComplete={handleEditorChange}
          variables={variables}
          kind={"rg"}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2 justify-end px-4 pt-2">
        <button onClick={() => setToggleEditor(1)}
                className="text-sm text-gray-100 bg-teal-500 px-2 py-1 rounded-md w-32">Code Edit
        </button>
        <button onClick={() => setToggleEditor(0)}
                className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md w-32">Done
        </button>
      </div>
      {!item.op ||
        (item.op === ComplexOperator.Standalone && (
          <div className={"flex flex-col gap-2"}>
            <RuleGroupAction
              kind={kind}
              onChange={updateRuleGroup(groupType, item, "actionKind", ownerId)}
              value={item.actionKind}
            />
          </div>
        ))}

      <div className={"flex flex-col gap-2 mt-2 border-t border-t-gray-300 pt-2"}>
        {item.rules.length ? (
          <RuleGroup group={item as QuestionRuleGroupEntity} owner={groupType} ownerId={ownerId} />
        ) : (
          <NoData
            message={
              "Group does not have any rules defined which means that this group will be ignored at runtime until rules are provided "
            }
          />
        )}
      </div>
    </div>

  );
};

export const RuleGroups = ({
                             groups,
                             kind,
                             groupType,
                             ownerId,
                           }: {
  ownerId?: string;
  groupType: RuleGroupOwner;
  groups: RuleGroupEntity[];
  kind: RuleGroupKind;
}) => {
  const { removeRuleGroup, insertRule, updateRuleGroup, currentGroup, resort } = useBuilderNGN();

  const handleImport = (data: string, group: RuleGroupEntity) => {
    const parsedData = JSON.parse(data);
    const updatedRules = parsedData.rules.map((rule: {}) => ({
      ...rule,
      op: ComplexOperator.And,
    }));

    updateRuleGroup(groupType, group, "rules", ownerId)([...group.rules, ...updatedRules]);
    updateRuleGroup(groupType, group, "actionKind", ownerId)(parsedData.actionKind || group.actionKind);
  };

  return (
    <div className={"flex flex-col gap-2"}>
      {groups
        .sort((a, b) => a.partOrder - b.partOrder)
        .map((item, idx) => (
          <div
            className={classNames(" border-t border-gray-200", {
              "mt-10": idx && item.op === ComplexOperator.Standalone,
            })}
          >
            <AccordionItem
              initial={true}
              title={
                <TitleWithAction
                  maxWidth="300px"
                  title={
                    <div className={"flex gap-2 w-80 "}>
                      {item.kind !== RuleGroupKind.Knockout && (
                        <ComplexOperatorToggle
                          onClick={updateRuleGroup(groupType, item, "op", ownerId)}
                          op={item.op}
                          omit={!idx ? [ComplexOperator.Or, ComplexOperator.And] : []}
                        />
                      )}
                      <div onClick={e => e.stopPropagation()} className="flex items-center gap-2">
                        <ImportAction onImport={data => handleImport(data, item)} />
                        <CopyAction value={JSON.stringify(item)} />
                        {/*<CopyAction value={ruleGroupToString(item)} invert />*/}
                      </div>
                    </div>
                  }
                  add={cb(insertRule, groupType, item, ownerId)}
                  remove={cb(removeRuleGroup(groupType, kind, ownerId), item.id)}
                  move={
                    groups.length > 1
                      ? d =>
                        resort("ruleGroup", {
                          groupId: currentGroup?.id || "",
                          questionId: currentGroup?.id || "",
                          ruleGroupId: item.id,
                          kind,
                        })(groups || [], item.partOrder, d)
                      : undefined
                  }
                />
              }
              key={`${item.id}-${idx}`}
            >
              <SingleRuleGroup groupType={groupType} item={item} kind={kind} ownerId={ownerId}
                               updateRuleGroup={updateRuleGroup} />
            </AccordionItem>
          </div>
        ))}
    </div>
  );
};

export const RuleGroup = ({
                            group,
                            owner,
                            ownerId,
                          }: {
  group: QuestionRuleGroupEntity;
  owner: RuleGroupOwner;
  ownerId?: string;
}) => {
  const { removeRule, editRule, context } = useBuilderNGN();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo(() => context(), []);

  return (
    <div className={"flex flex-col bg-white flex-1 gap-2"}>
      {group.rules.map((item, idx) => (
        <div className={"flex gap-2 w-max rounded justify-between items-start px-2 py-[2px]"} key={`${item.id}-${idx}`}>
          <Rule rule={item} editRule={editRule(owner, group, ownerId)} context={ctx} idx={idx} />
          <RemoveAction remove={cb(removeRule, owner, group, item.id || "", ownerId)} />
        </div>
      ))}
    </div>
  );
};

/*
OWN Value integration OwnValues from questionnaire question values
*/

import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import React, { useCallback, useMemo, useState } from "react";
import { CopyAction, ImportAction, RemoveAction } from "../util/ItemActions";
import { cb } from "../../utils/cd";
import { JsonNodeValue } from "./JsonNodeValue";
import { remove } from "../../utils/arr-utils";
import { v4 } from "uuid";
import { Rule } from "../Rules/Rule";
import { QuestionContext } from "../../types/QuestionContext";
import { OpUI } from "../Op/OpUI";
import { CarrierQuestion, RuleGroupEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { ComplexOperator, Op, Rule as R, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { NoData } from "../util/Empty";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import { opToString } from "@Savus-Inc/dsl/dist/utils/transformer";
import { RuleEditor } from "../RuleEditor/RuleEditor";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";

const ValueEditor = ({ valueRule, done }: { valueRule: string, done: (value: Op) => void }) => {
  const { context } = useBuilderNGN();

  const variables = useMemo(() => {
    return getAllRefs(context());
  }, [context]);


  if (!valueRule || !variables.length) return <div className="flex items-center justify-center h-full">
    <div className="w-8 h-8 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
  </div>;

  return <div className={"w-full flex"}><RuleEditor
    value={valueRule}
    onComplete={done}
    variables={variables}
    kind={"op"}
  /></div>;
};

function QuestionValueMapping({ valueRule, updateRule }: { valueRule: Op; updateRule: (value: Op) => void }) {
  const { item } = useBuilderNGN();
  const [toggleEditor, setToggleEditor] = useState(0);

  const update = useCallback(
    (key: keyof Op) => (value: Op[keyof Op]) => {
      (valueRule as any)[key] = value;
      updateRule(valueRule);
    },
    [valueRule, updateRule],
  );

  const updateFnName = useCallback(
    (fn: Op["fn"]) => {
      updateRule({
        fn,
        args: [],
        condition: valueRule.condition,
      });
    },
    [updateRule, valueRule],
  );

  const editRule = useCallback(
    (idx: number) => (rule: R, key: keyof R) => (val: R[typeof key]) => {
      if (!valueRule.condition) return;
      (rule as any)[key] = val;
      valueRule.condition.rules[idx] = rule;
      updateRule(valueRule);
    },
    [updateRule, valueRule],
  );

  const removeRule = useCallback(
    (idx: number) => () => {
      if (!valueRule.condition) return;
      remove(valueRule.condition.rules, idx);
      updateRule(valueRule);
    },
    [updateRule, valueRule],
  );

  const addRule = useCallback(() => {
    if (!valueRule.condition) return;
    valueRule.condition?.rules?.push({
      op: valueRule.condition.rules.length ? ComplexOperator.And : ComplexOperator.Standalone,
      value: {
        id: v4(),
        fn: ValueFunctions.Is,
        args: [],
      },
      id: v4(),
      partOrder: valueRule.condition?.rules?.length,
    } as never);
    updateRule(valueRule);
  }, [updateRule, valueRule]);

  const context: QuestionContext = useMemo(() => {
    const groupIdx = item.groups.length - 1;
    const questionIdx = item.groups[groupIdx].questions.length;
    return {
      questionnaire: item,
      me: {
        questionIdx,
        groupIdx,
      },
    };
  }, [item]);

  const addCondition = useCallback(() => {
    if (!valueRule.condition) {
      update("condition")({
        actionKind: null,
        op: ComplexOperator.Standalone,
        id: v4(),
        kind: RuleGroupKind.Knockout,
        name: "Condition",
        not: false,
        partOrder: 0,
        rules: [
          {
            op: ComplexOperator.Standalone,
            id: v4(),
            value: {
              fn: ValueFunctions.Is,
              args: [],
            },
          },
        ],
      } as RuleGroupEntity);
    } else {
      addRule();
    }
  }, [addRule, update, valueRule]);

  const str = useMemo(() => opToString(valueRule), [valueRule]);

  if (toggleEditor === 0) {
    return (
      <div className="flex flex-col gap-2 w-full pr-11">
        <div className="flex items-center gap-2 justify-end px-4 pt-2 w-full">
          <button onClick={() => setToggleEditor(1)}
                  className="text-sm text-gray-100 bg-teal-500 px-2 py-1 rounded-md w-32">Code Edit
          </button>
          <button onClick={() => setToggleEditor(2)}
                  className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md w-32">Builder Edit
          </button>
        </div>

        <div className="rounded-lg border border-gray-200 bg-teal-50 m-2 p-4 font-mono text-sm relative">
          <div className="absolute top-0 right-2 text-gray-500 text-xs">
            <CopyAction value={str} />
          </div>
          <pre className="whitespace-pre-wrap break-words text-gray-700 leading-relaxed">
            {str || "No rule defined"}
          </pre>
        </div>
      </div>
    );
  }

  if (toggleEditor === 1) {
    return (
      <div className="flex flex-col gap-2 w-full">
        <div className="flex items-center gap-2 justify-end px-4 pt-2 transform -translate-x-36">
          <button onClick={() => setToggleEditor(0)}
                  className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md w-32">Close
          </button>
        </div>
        <ValueEditor done={(v) => {
          updateRule(v);
          setToggleEditor(0);
        }} valueRule={str} />
      </div>
    );
  }

  return (
    <div className={"flex flex-col w-full"}>
      <div className="flex items-center gap-2 justify-end px-4 pt-2 pr-16">
        <button onClick={() => setToggleEditor(1)}
                className="text-sm text-gray-100 bg-teal-500 px-2 py-1 rounded-md w-32">Code Edit
        </button>
        <button onClick={() => setToggleEditor(0)}
                className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md w-32">Done
        </button>
      </div>
      <div className={"flex items-center gap-4 w-full pb-2 px-1"}>
        <div className={"flex-col flex justify-center pt-5"}>
          Data <span className={"text-sm text-gray-500"}>(Reference or plain)</span>`
        </div>
        <div className={"flex gap-2 items-start"}>
          <OpUI op={valueRule} update={update("args")} updateFunction={updateFnName} context={context} />
        </div>
      </div>
      <div className={"-mt-2 mb-2"}>
        <AccordionItem
          initial={false}
          title={
            <TitleWithAction
              title={`Condition (${valueRule.condition?.rules?.length ?? 0})`}
              add={addCondition}
              remove={cb(update("condition"), undefined)}
            />
          }
        >
          {valueRule.condition && (
            <div className={"flex flex-col bg-white flex-1 gap-2"}>
              {valueRule.condition.rules.map((item, idx) => (
                <div className={"flex gap-2 items-start py-[2px]"} key={`${item.id}-${idx}`}>
                  <Rule rule={item as never} editRule={editRule(idx)} context={context} />
                  <RemoveAction remove={removeRule(idx)} />
                </div>
              ))}
            </div>
          )}
        </AccordionItem>
      </div>
    </div>
  );
}

export const CarrierMappingQuestions = () => {
  const { item, questions, setCarrierMappingItem, carrierId, carrierMappingQuestionId } = useBuilderNGN();
  const carrier = useMemo(() => {
    return item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
  }, [carrierId, item]);

  const [question, idx] = useMemo(() => {
    const idx = (carrier?.questions || []).findIndex(b => b.id === carrierMappingQuestionId);
    return [(carrier?.questions || [])[idx], idx];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, carrier, carrierMappingQuestionId]);

  const updateItem = useCallback(
    (idx: number, key: keyof CarrierQuestion) =>
      <T = unknown, >(value: T) => {
        if (!question) return;

        setCarrierMappingItem(carrierId as string, idx, ["questions", { ...question, [key]: value }]);
      },
    [question, setCarrierMappingItem, carrierId],
  );

  const updateValueRule = useCallback(
    (idx: number, item: CarrierQuestion, innerIdx: number) => (op: Op) => {
      item.valueRules[innerIdx] = op;

      updateItem(idx, "valueRules")(item.valueRules);
    },
    [updateItem],
  );

  const suggestions = useMemo(
    () => questions.map(v => ({
      value: v.externalId || v.question?.externalId as string,
      label: `${v.question?.externalId} ${v.question?.label}`,
    })),
    [questions],
  );

  const value = useMemo(() => {
    return (
      questions.find(v => v.externalId === question?.referencedQuestionId)?.question?.label ||
      "Select Referenced Question"
    );
  }, [question, suggestions]);

  if (!question) return <NoData />;

  return (
    <div
      className={"flex flex-col gap-2 border rounded p-2 px-4  shadow relative  min-w-[720px] bg-white min-h-[89vh]"}
    >
      <div className={"flex gap-2 py-2  border-gray-200 border-b"}>
        <div className={"text-gray-800 font-semibold w-48"}>Referenced Value</div>
        <SelectAutocomplete
          suggestions={suggestions}
          onSelect={updateItem(idx, "referencedQuestionId")}
          value={value}
        />

        <CopyAction value={JSON.stringify(question)} />
        <ImportAction
          onImport={data => {
            setCarrierMappingItem(carrierId as string, idx, ["questions", { ...JSON.parse(data), id: question.id }]);
          }}
        />
      </div>

      <div className={"flex gap-2 py-2 flex-col border-gray-200 border-b"}>
        <div className={"text-gray-800 font-semibold w-24"}>JSON Node</div>
        <div>
          <JsonNodeValue item={question} items={carrier?.questions || []} onChange={updateItem(idx, "jsonNode")} />
          <span className={"text-xs text-gray-700 text-center"}>
            <i>(Used for remap to carrier expected payload)</i>
          </span>
        </div>
      </div>

      <AccordionItem
        title={
          <TitleWithAction
            title={`Value Rules (${question.valueRules.length})`}
            add={cb(updateItem(idx, "valueRules"), [...question.valueRules, { fn: ValueFunctions.Value, args: [] }])}
          />
        }
        key={`${item.id}-${idx}`}
      >
        <div className={"flex flex-col gap-6 bg-gray-50 w-full"}>
          {question.valueRules.map((rule, i) => (
            <div className={"flex gap-2 items-center relative bg-white w-full"} key={i}>
              <QuestionValueMapping valueRule={rule} updateRule={updateValueRule(idx, question, i)} />
              <div className={" right-4 absolute bottom-4 w-fit h-fit bg-red"}>
                <RemoveAction
                  remove={() => {
                    remove(question.valueRules, i);
                    updateItem(idx, "valueRules")(question.valueRules);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </AccordionItem>
    </div>
  );
};

interface LoadingScreenProps {
    message?: string;
}

export const LoadingScreen = ({ message = "Loading..." }: LoadingScreenProps) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Backdrop with glass effect */}
            <div className="absolute inset-0 bg-white/80 backdrop-blur-sm" />

            {/* Loading content */}
            <div className="relative flex flex-col items-center p-8 rounded-2xl bg-white/40 backdrop-blur-md shadow-xl border border-white/20">
                {/* Spinner */}
                <div className="relative">
                    {/* Outer spinning ring */}
                    <div className="w-24 h-24 rounded-full border-t-4 border-b-4 border-indigo-500 animate-spin" />
                    
                    {/* Inner spinning ring */}
                    <div className="absolute top-2 left-2 w-20 h-20 rounded-full border-r-4 border-l-4 border-indigo-300 animate-spin-slow" />
                    
                    {/* Center dot */}
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div className="w-4 h-4 bg-indigo-500 rounded-full animate-pulse" />
                    </div>
                </div>

                {/* Loading text */}
                <div className="mt-8 text-center">
                    <p className="text-lg font-medium text-gray-700 animate-pulse">
                        {message}
                    </p>
                    <p className="mt-2 text-sm text-gray-500">
                        Please wait while we process your request
                    </p>
                </div>

                {/* Decorative dots */}
                <div className="absolute -top-4 -left-4 w-24 h-24 bg-gradient-to-r from-indigo-500/20 to-purple-500/20 rounded-full blur-xl animate-blob" />
                <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-gradient-to-r from-pink-500/20 to-indigo-500/20 rounded-full blur-xl animate-blob animation-delay-2000" />
            </div>
        </div>
    );
}; 
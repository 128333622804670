import { CustomerRecord, QuestionnaireSubmissionStatus, QuoteSubmission } from '../../types/customer-record';

export const calculateAverageQuoteValue = (customerRecords: CustomerRecord[]): number => {
    const completedQuotes = customerRecords.flatMap(record => 
        record.questionnaireState?.flatMap(qs => 
            qs.submissions.filter(s => 
                s.status === QuestionnaireSubmissionStatus.Completed && s.coverage
            )
        ) ?? []
    );

    if (completedQuotes.length === 0) return 0;

    const totalValue = completedQuotes.reduce((sum, quote) => 
        sum + (quote.coverage?.totalAmount ?? 0), 0
    );

    return totalValue / completedQuotes.length;
};

export const getTotalQuotes = (customerRecords: CustomerRecord[]): (QuoteSubmission|undefined)[] => {
    const completedQuotes = customerRecords.flatMap(record => record.questionnaireState?.flatMap(qs => qs.submissions));

    return completedQuotes
};

export const calculateConversionRate = (customerRecords: CustomerRecord[]): number => {
    if (customerRecords.length === 0) return 0;

    const completedQuotes = getTotalQuotes(customerRecords);

    return completedQuotes.filter(isQuoteCompleted).length / completedQuotes.length;
};

export const formatDate = (date: string | undefined): string => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

export const getLatestStatus = (record: CustomerRecord): string => {
    const latestQuestionnaire = record.questionnaireState
        ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())[0];

    if (!latestQuestionnaire) return 'Not Submitted';

    const latestSubmission = latestQuestionnaire.submissions
        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())[0];

    return latestSubmission?.status ?? 'Not Submitted';
};

export const getQuoteValue = (record: CustomerRecord): string => {
    const highestQuoteValue = Math.max(
        ...record.questionnaireState?.flatMap(qs =>
            qs.submissions.map(s => s.coverage?.totalAmount ?? 0)
        ) ?? [0]
    );

    return highestQuoteValue > 0 
        ? `$${highestQuoteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
        : 'No Quote';
}; 

export const isQuoteCompleted = (record: QuoteSubmission | undefined): boolean => {
    if (!record) return false;
    
    return ![QuestionnaireSubmissionStatus.Errored, QuestionnaireSubmissionStatus.RequestTimeout, QuestionnaireSubmissionStatus.Started].includes(record?.status);
};
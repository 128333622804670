import { CustomerRecord, QuestionnaireSubmissionStatus } from "../../types/customer-record";
import { calculateConversionRate, getTotalQuotes, isQuoteCompleted } from "./helpers";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

interface PerformanceMetricsProps {
  customerRecords: CustomerRecord[];
  className?: string;
}

// Define colors for each status type with glossy variants
const STATUS_COLORS = {
  [QuestionnaireSubmissionStatus.Completed]: {
    main: "#10B981", // Green
    light: "#34D399", // Lighter green for glossy effect
  },
  [QuestionnaireSubmissionStatus.Started]: {
    main: "#3B82F6", // Blue
    light: "#60A5FA", // Lighter blue
  },
  [QuestionnaireSubmissionStatus.Errored]: {
    main: "#EF4444", // Red
    light: "#F87171", // Lighter red
  },
  [QuestionnaireSubmissionStatus.RequestTimeout]: {
    main: "#F59E0B", // Amber
    light: "#FBBF24", // Lighter amber
  },
  [QuestionnaireSubmissionStatus.Declined]: {
    main: "#6B7280", // Gray
    light: "#9CA3AF", // Lighter gray
  },
  [QuestionnaireSubmissionStatus.SubjectToReferral]: {
    main: "#8B5CF6", // Purple
    light: "#A78BFA", // Lighter purple
  },
  [QuestionnaireSubmissionStatus.HasNoAppetiteToQuote]: {
    main: "#9CA3AF", // Light gray
    light: "#D1D5DB", // Lighter gray
  },
  [QuestionnaireSubmissionStatus.CustomerNotCleared]: {
    main: "#EC4899", // Pink
    light: "#F472B6", // Lighter pink
  },
};

// Create a mapping function to get colors
const getStatusColor = (status: string) => {
  return STATUS_COLORS[status as QuestionnaireSubmissionStatus]?.main || "#6B7280"; // Default to gray
};

export const PerformanceMetrics = ({ customerRecords, className }: PerformanceMetricsProps) => {

  const submissions = getTotalQuotes(customerRecords);

  const metrics = {
    totalBusinesses: customerRecords.length,
    totalQuotes: customerRecords.flatMap(record => record.questionnaireState?.map(qs => qs) || []).length,
    totalSubmissions: submissions.length,
    completedSubmissions: submissions.filter(isQuoteCompleted).length,
    erroredSubmissions: submissions.filter(s => s?.status !== QuestionnaireSubmissionStatus.Started && !isQuoteCompleted(s)).length,
    conversionRate: calculateConversionRate(customerRecords),
  };

  // Prepare data for submission status chart - filter out statuses with zero values
  const submissionStatusData = Object.values(QuestionnaireSubmissionStatus)
    .map(status => ({
      status,
      value: submissions.filter(s => s?.status === status).length,
    }))
    .filter(item => item.value > 0) // Only show statuses that have values
    .sort((a, b) => b.value - a.value); // Sort by value descending

  // Prepare data for daily activity chart with all statuses
  const dailyActivity = getDailyActivityData(customerRecords);

  // Get active status types (those that actually have data)
  const activeStatusTypes = Object.values(QuestionnaireSubmissionStatus)
    .filter(status =>
      dailyActivity.some(day => (day as any)[status] > 0),
    );

  // Custom bar shape for glossy effect with value labels
  const GlossyBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const statusKey = props.payload.status as QuestionnaireSubmissionStatus;
    const colors = STATUS_COLORS[statusKey] || { main: "#6B7280", light: "#9CA3AF" };
    const value = props.payload.value;

    // Calculate text position
    const textX = x + width / 2;
    const textY = y - 8; // Position slightly above bar

    return (
      <g>
        {/* Main bar */}
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={colors.main}
          rx={6}
          ry={6}
        />
        {/* Glossy highlight */}
        <rect
          x={x + 2}
          y={y + 2}
          width={width - 4}
          height={height * 0.4}
          fill={colors.light}
          rx={4}
          ry={4}
          opacity={0.7}
        />
        {/* Value text above bar */}
        <text
          x={textX}
          y={textY}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#374151" // Dark gray text
          fontSize={12}
          fontWeight="bold"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={`space-y-6 ${className}`}>
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <MetricCard
          title="Total Businesses"
          value={metrics.totalBusinesses}
        />
        <MetricCard
          title="Total Quotes"
          value={metrics.totalQuotes}
        />
        <MetricCard
          title="Success Rate"
          value={`${(metrics.conversionRate * 100).toFixed(1)}%`}
        />
        <MetricCard
          title="Total Submissions"
          value={metrics.totalSubmissions}
        />
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Submission Status Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Submission Status Distribution</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={submissionStatusData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(255, 255, 255, 0.95)",
                    borderRadius: "6px",
                    border: "none",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  }}
                  formatter={(value, name, props) => [
                    `${value} submission${value !== 1 ? "s" : ""}`,
                    "Count",
                  ]}
                  labelFormatter={(label) => `Status: ${label}`}
                />
                <Legend />
                <Bar
                  dataKey="value"
                  name="Submissions"
                  barSize={36}
                  shape={<GlossyBar />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Daily Activity Chart - Enhanced with all statuses */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Daily Submission Activity</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={dailyActivity}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                  }}
                />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(255, 255, 255, 0.95)",
                    borderRadius: "6px",
                    border: "none",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  }}
                  labelFormatter={(value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    });
                  }}
                />
                <Legend />


                {/* Generate lines for each active status type */}
                {activeStatusTypes.map(status => (
                  <Line
                    key={status}
                    name={status}
                    type="monotone"
                    dataKey={status}
                    stroke={STATUS_COLORS[status]?.main}
                    strokeWidth={1.5}
                    dot={{ fill: STATUS_COLORS[status]?.main }}
                    activeDot={{ r: 6 }}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

interface MetricCardProps {
  title: string;
  value: string | number;
  trend?: string;
  trendUp?: boolean;
}

const MetricCard = ({ title, value, trend, trendUp }: MetricCardProps) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <h3 className="text-gray-600 text-sm">{title}</h3>
    <p className="text-2xl font-bold mt-2 text-gray-900">{value}</p>
    {trend && (
      <p className={`text-sm mt-2 flex items-center ${trendUp ? "text-green-600" : "text-red-600"}`}>
        {trendUp ? (
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
          </svg>
        ) : (
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        )}
        {trend}
      </p>
    )}
  </div>
);

// Helper function to generate daily activity data for all status types
const getDailyActivityData = (customerRecords: CustomerRecord[]) => {
  // Initialize empty daily data object
  const dailyData: {
    [date: string]: {
      total: number;
      [key: string]: number;  // Will store counts for all status types
    }
  } = {};

  // Process all customer records to extract activity data
  customerRecords.forEach(record => {
    record.questionnaireState?.forEach(qs => {
      // Process submissions for this questionnaire state
      qs.submissions.forEach(submission => {
        if (!submission.updatedAt) return; // Skip if no submission date

        try {
          const submissionDate = new Date(submission.updatedAt);
          if (isNaN(submissionDate.getTime())) return;

          const dateKey = submissionDate.toISOString().split("T")[0];

          // Initialize the date entry if it doesn't exist
          if (!dailyData[dateKey]) {
            // Create entry with all status types initialized to 0
            dailyData[dateKey] = {
              total: 0,
              // Initialize all status types to 0
              ...Object.values(QuestionnaireSubmissionStatus).reduce(
                (acc, status) => ({ ...acc, [status]: 0 }),
                {},
              ),
            };
          }

          // Increment the specific status count
          if (submission.status) {
            dailyData[dateKey][submission.status] =
              (dailyData[dateKey][submission.status] || 0) + 1;
          }

          // Increment total
          dailyData[dateKey].total += 1;
        } catch (error) {
          console.warn("Invalid date encountered:", submission.updatedAt);
        }
      });
    });
  });

  // Get all dates with activity, sort chronologically
  const allDates = Object.keys(dailyData).sort();

  // If no data, return empty array
  if (allDates.length === 0) {
    return [];
  }

  // Convert sorted dailyData to array format for Recharts
  const chartData = allDates.map(date => ({
    date,
    total: dailyData[date].total,
    ...Object.values(QuestionnaireSubmissionStatus).reduce(
      (acc, status) => ({ ...acc, [status]: dailyData[date][status] || 0 }),
      {},
    ),
  }));

  return chartData;
}; 

export type FlattenedObject = Record<string, string>;

export function flattenObject(obj: any, prefix = ""): FlattenedObject[] {
  let results: FlattenedObject[] = [{}];

  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        results.forEach(result => result[newKey] = "");
      } else if (typeof value[0] === "object" && value[0] !== null) {
        // For arrays of objects, create new rows
        const newResults: FlattenedObject[] = [];
        results.forEach(existingResult => {
          value.forEach(item => {
            const flattenedItems = flattenObject(item, newKey);
            flattenedItems.forEach(flattenedItem => {
              newResults.push({ ...existingResult, ...flattenedItem });
            });
          });
        });
        results = newResults;
      } else {
        // For arrays of primitives, join with comma
        results.forEach(result => result[newKey] = value.join(","));
      }
    } else if (value !== null && typeof value === "object") {
      // Recursively flatten nested objects
      const flattenedItems = flattenObject(value, newKey);
      results.forEach(result => Object.assign(result, flattenedItems[0]));
    } else {
      // Handle primitive values
      results.forEach(result => result[newKey] = value !== null ? String(value) : "");
    }
  }

  return results;
}

export function getValueByPath(obj: any, path: string): any {
  return path.split(".").reduce((curr, key) => curr?.[key], obj);
}

export function parseFileContent(content: string): any {
  // Try parsing as regular JSON first
  try {
    return JSON.parse(content);
  } catch (e) {
    // If regular JSON parsing fails, try parsing as newline-separated JSON
    try {
      // Split by newlines and filter out empty lines
      const lines = content.split("\n").filter(line => line.trim());

      // Try to parse each line as JSON
      const jsonArray = lines.map(line => {
        try {
          return JSON.parse(line);
        } catch (err) {
          throw new Error(`Invalid JSON in line: ${line}`);
        }
      });

      return jsonArray;
    } catch (err: any) {
      throw new Error(`Failed to parse file: ${err.message}`);
    }
  }
}
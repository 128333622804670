import React, { useMemo } from "react";
import { NoData } from "../util/Empty";
import classNames from "classnames";
import {
  CarrierQuestionnaire,
  QuestionValidityAction,
  RuleGroupEntity,
  RuleGroupKind,
} from "@Savus-Inc/questionnaire-types";
import { RemoveAction } from "../util/ItemActions";
import { cb } from "../../utils/cd";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { v4 } from "uuid";
import { useAppState } from "../../state/State";

export const UploadKnockoutRules = ({ carrierId }: { carrierId: string }) => {
  const { state: { carriers } } = useAppState();

  const { bulkSetKnockoutRules, item } = useBuilderNGN();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type?.toLowerCase()?.includes("json")) {
      const data = JSON.parse(await file?.text());
      bulkSetKnockoutRules(
        carrierId,
        data.map((v: RuleGroupEntity<QuestionValidityAction>) => {
          v.id = v4();
          v.kind = RuleGroupKind.Knockout;

          return v;
        }),
      );
      return;
    }
    alert("CSV Import is not yet supported");
  };

  const carrier = useMemo(() => carriers.find(v => v.id === carrierId)?.name || "unknown", [carriers, carrierId]);
  const rules = useMemo(() => JSON.stringify(item.carrierQuestionnaires.find(q => q.carrierId === carrierId)?.rules || []), [carrierId, item]);


  return (
    <div className="flex items-center gap-2 mb-2">
      <a
        className="py-1.5 px-3 rounded-xl border border-gray-200 text-sky-600 "
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          rules,
        )}`}
        download={`${carrier}-rules.json`}
      >
        Download
      </a>
      <div className="w-full max-w-lg flex flex-col justify-center ">
        <input
          type="file"
          accept=".csv,.json"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 bg-gray-50  px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          "
        />
      </div>
    </div>
  );
};

export const KnockoutRules = ({ carrierId, carrierMap }: { carrierId: string; carrierMap: CarrierQuestionnaire }) => {
  const { groupPartActive, activateGroupPart, carrierMappingRuleId, removeCarrierMappingItem } = useBuilderNGN();

  if (!carrierMap?.rules?.length) {
    return <div className={"flex flex-col gap-3"}>
      <UploadKnockoutRules carrierId={carrierId} />
      <NoData message={"No Knockout Rules Found"} />
    </div>;
  }

  return (
    <div className={"flex flex-col gap-3"}>
      <UploadKnockoutRules carrierId={carrierId} />
      {carrierMap.rules.map(q => (
        <div
          key={q.id}
          className={classNames(
            "flex gap-2 items-center justify-between border-sky-200 border-b py-[2px] cursor-pointer px-2 rounded",
            {
              "bg-gray-100": groupPartActive === "c-knockout" && q.id === carrierMappingRuleId,
            },
          )}
        >
          <div
            className={"w-full max-w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap "}
            onClick={cb(activateGroupPart, { carrier: carrierId, groupPart: "c-knockout", knockoutRuleId: q.id })}
          >
            {(q?.actionKind as QuestionValidityAction)?.message}
          </div>
          <RemoveAction remove={cb(removeCarrierMappingItem, carrierId, q.id, "rules")} small invert />
        </div>
      ))}
    </div>
  );
};

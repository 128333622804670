import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ComplexOperator, Rule, Val, ValueFunctions } from "@Savus-Inc/dsl/dist/types";

import {
  CarrierDefault,
  CarrierQuestion,
  CarrierQuestionnaire,
  DisplayDirection,
  Question,
  QuestionAction,
  QuestionActionKind,
  Questionnaire,
  QuestionnaireGroupSection,
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionGroupEntity,
  QuestionValidityAction,
  QuestionVisibility,
  RuleGroupActionMetadata,
  RuleGroupEntity,
  RuleGroupKind,
  ValidationAlertLevel,
} from "@Savus-Inc/questionnaire-types";
import { insert, moveArrayItem, remove } from "../../utils/arr-utils";
import { v4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import { defer } from "../../utils/defer";
import { QuestionContext } from "../../types/QuestionContext";
import { useAppState } from "../../state/State";
import useAsyncFn from "../../utils/useAsyncFn";
import { getQuestionnaireCarrierMapping } from "../../http/questionnaire";
import { stringToOp, stringToRuleGroup } from "@Savus-Inc/dsl/dist/utils/transformer";

export type RuleGroupOwner = "questionGroup" | "question" | "knockout" | "optionValue";

export type ActiveGroupPart =
  | "g-details"
  | "g-questions"
  | "g-rules"
  | "q-details"
  | "c-knockout"
  | "c-questions"
  | "c-defaults";
export type CarrierMappingPart = "defaults" | "questions" | "rules";

export type ResortMetadata = {
  groupId: string;
  sectionId?: string;
  questionId?: string;
  ruleGroupId?: string;
  ruleId?: string;
  kind?: RuleGroupKind;
};
export type BuilderNGN = {
  item: Questionnaire;
  questions: QuestionnaireQuestionEntity[];
  editQuestionnaireDetails: (key: "title" | "name" | "subText" | "lineOfBusinessId") => (value: string) => void;
  insertGroup: () => void;
  groupId: string | null;
  changeGroup: (g: string | null) => void;
  currentGroup: QuestionnaireQuestionGroupEntity | undefined;
  removeGroup: (groupId: string) => void;
  activateGroupPart: (meta: { groupPart: ActiveGroupPart } & Record<string, string | number | boolean>) => void;
  groupPartActive: ActiveGroupPart;
  editQuestionGroupDetails: (key: "title" | "subText" | "displaySections") => (value: string) => void;
  insertQuestion: (groupId: string, questionId?: string) => void;
  removeQuestion: (groupId: string, questionId: string) => void;
  updateQuestion: (k: keyof QuestionnaireQuestionEntity) => (v: string | null | number | string[]) => void;
  openQuestion: (groupId: string, id: string) => void;
  selectedQuestion: QuestionnaireQuestionEntity | undefined;
  changeQuestionUIDetails: (key: keyof Question) => (value: Question[typeof key]) => void;
  insertRuleGroup: (owner: RuleGroupOwner, kind: RuleGroupKind, ownerId?: string, group?: RuleGroupEntity) => void;
  removeRuleGroup: (owner: RuleGroupOwner, kind: RuleGroupKind, ownerId?: string) => (id: string) => void;
  updateRuleGroup: (
    owner: RuleGroupOwner,
    group: RuleGroupEntity,
    key: keyof RuleGroupEntity,
    ownerId?: string,
  ) => (val: RuleGroupEntity[typeof key]) => void;
  insertRule: (owner: RuleGroupOwner, group: RuleGroupEntity, ownerId?: string) => void;
  removeRule: (owner: RuleGroupOwner, group: RuleGroupEntity, ruleId: string, ownerId?: string) => void;
  editRule: (
    owner: RuleGroupOwner,
    group: RuleGroupEntity,
    ownerId?: string,
  ) => (rule: Rule, key: keyof Rule) => (val: Rule[typeof key]) => void;
  context: () => QuestionContext;
  insertCarrierMapping: (data: Pick<CarrierQuestionnaire, "carrierId" | "carrierName">) => void;
  setCarrierMapping: (carrierId: string, data: Pick<CarrierQuestionnaire, "carrierId" | "carrierName">) => void;
  removeCarrierMapping: (carrierId: string, data: CarrierQuestion) => void;
  insertCarrierMappingItem: (carrierId: string, item: CarrierMappingPart) => void;
  setCarrierMappingItem: (
    carrierId: string,
    idx: number,
    data: ["questions", CarrierQuestion] | ["defaults", CarrierDefault] | ["rules", RuleGroupEntity<QuestionAction>],
  ) => void;
  removeCarrierMappingItem: (carrierId: string, id: string, item: CarrierMappingPart) => void;
  carrierId: string | null;
  carrierMappingRuleId: string | null;
  carrierMappingDefaultId: string | null;
  carrierMappingQuestionId: string | null;
  removeSection: (sectionId: string) => void;
  addSection: () => void;
  updateSection: (
    sectionId: string,
  ) => (key: keyof QuestionnaireGroupSection) => (value: QuestionnaireGroupSection[typeof key]) => void;
  resort: (
    itemKind: "page" | "section" | "question" | "ruleGroup",
    metadata: ResortMetadata,
  ) => (items: { partOrder: number; id: string }[], idx: number, direction: 1 | -1) => void;
  selectedCarrier: CarrierQuestionnaire | undefined;
  bulkSetCarrierMapping: (carrierId: string, mapping: CarrierQuestion[]) => void;
  bulkSetCarrierDefaults: (carrierId: string, mapping: CarrierDefault[]) => void;
  bulkUpdateQuestion: (updatedQuestionData: QuestionnaireQuestionEntity) => void;
  bulkUpdateGroup: (updatedGroupData: QuestionnaireQuestionGroupEntity) => void;
  bulkSetKnockoutRules: (carrierId: string, rules: RuleGroupEntity<QuestionValidityAction>[]) => void
};

const BuilderNGNCtx = createContext({} as BuilderNGN);

export const useBuilderNGN = () => useContext(BuilderNGNCtx);

export const BuilderNGNProvider = ({
                                     children,
                                     questionnaire,
                                   }: PropsWithChildren<{ questionnaire: Questionnaire }>) => {
  const [search, change] = useSearchParams();

  const {
    editQuestionnaireDetails: updateQuestionnaireDetails,
    addPage,
    editPage,
    deletePage,
    addSection: addSectionR,
    editSection,
    deleteSection,
    addQuestion,
    editReferentQuestion,
    removeQuestion: removeQuestionR,
    addQuestionRuleGroup,
    removeQuestionRuleGroup,
    editQuestionRuleGroup,
    updateQuestionRuleGroupRules,
    editQuestion,
    addKnockoutRule,
    updateKnockoutRuleRules,
    editKnockoutRule,
    removeKnockoutRule,
    addCarrierDefault,
    editCarrierDefault,
    removeCarrierDefault,
    addCarrierDataMap,
    editCarrierDataMap,
    removeCarrierDataMap,
    bulkAddCarrierDataMap,
    bulkAddCarrierDefaults,
    addReferentQuestionValueVisibilityGroup,
    removeReferentQuestionValueVisibilityGroup,
    editReferentQuestionValueVisibilityGroup,
    questionnaireBulkInsert,
    bulkAddCarrierKnockoutRules,
  } = useAppState();

  const [item, setItem] = useState({
    ...questionnaire,
    carrierQuestionnaires: questionnaire.carrierQuestionnaires || [],
  });

  const questions = useMemo(() => item.groups.flatMap(g => g.questions), [item]);

  const resort = useCallback(
    (itemKind: "page" | "section" | "question" | "ruleGroup", metadata: ResortMetadata) =>
      (items: { partOrder: number; id: string }[], idx: number, direction: 1 | -1) => {
        const newIdx = idx + direction;
        if (newIdx < 0 || newIdx >= items.length) {
          return;
        }

        moveArrayItem(items, idx, direction);
        setItem({ ...item });

        switch (itemKind) {
          case "page":
            editPage(item.id, metadata.groupId, { partOrder: newIdx });
            break;
          case "section":
            editSection(item.id, metadata.groupId, metadata.sectionId || "", { partOrder: newIdx });
            break;
          case "question":
            editQuestion(item.id, metadata.groupId, metadata.questionId || "", { partOrder: newIdx });
            break;
          case "ruleGroup":
            editQuestionRuleGroup(item.id, metadata.groupId, metadata.questionId || "", metadata.ruleGroupId || "", {
              kind: metadata.kind,
              partOrder: newIdx,
            });
            break;
        }
      },
    [editPage, editQuestion, editQuestionRuleGroup, editSection, item, updateQuestionRuleGroupRules],
  );
  const { exec: getMappings, data: mappingsResponse } = useAsyncFn(getQuestionnaireCarrierMapping);

  const groupId = useMemo(() => search.get("group"), [search]);
  const carrierMappingRuleId = useMemo(() => search.get("knockoutRuleId"), [search]);
  const carrierMappingDefaultId = useMemo(() => search.get("defaultsId"), [search]);
  const carrierMappingQuestionId = useMemo(() => search.get("dataMapId"), [search]);
  const carrierId = useMemo(() => search.get("carrier"), [search]);

  const groupPartActive = useMemo(() => (search.get("groupPart") as ActiveGroupPart) || "g-details", [search]);

  const currentGroup = useMemo(() => item.groups.find(g => g.id === groupId), [item, groupId]);

  const activateGroupPart = useCallback(
    (meta: { groupPart: ActiveGroupPart } & Record<string, string | number | boolean>) => {
      search.delete("questionId");
      search.delete("knockoutRuleId");
      search.delete("defaultsId");
      search.delete("carrierMappingQuestionId");

      search.delete("groupPart");
      search.delete("group");
      search.delete("carrier");

      Object.entries(meta).forEach(([k, v]) => {
        search.set(k, v.toString());
      });

      change(search);
    },
    [search, change],
  );

  const bulkUpdateGroup = (updatedGroupData: QuestionnaireQuestionGroupEntity) => {
    let idx = item.groups.findIndex(g => g.id === updatedGroupData.id);
    item.groups[idx] = updatedGroupData;

    questionnaireBulkInsert(item.id, { id: item.id, groups: [updatedGroupData] } as Questionnaire);

    setItem({ ...item });
  };

  const bulkUpdateQuestion = (updatedQuestionData: QuestionnaireQuestionEntity) => {
    if (!currentGroup) {
      return;
    }
    let idx = currentGroup?.questions.findIndex(q => q.id === updatedQuestionData.id);
    currentGroup.questions[idx] = updatedQuestionData;

    questionnaireBulkInsert(item.id, {
      id: item.id,
      groups: [{ ...currentGroup, questions: [updatedQuestionData] }],
    } as Questionnaire);

    setItem({ ...item });
  };

  const editQuestionnaireDetails = useCallback(
    (key: "title" | "name" | "subText" | "lineOfBusinessId") => (value: string) => {
      setItem(item => {
        updateQuestionnaireDetails(item.id, { [key]: value });
        return { ...item, [key]: value };
      });
    },
    [updateQuestionnaireDetails],
  );

  const changeGroup = useCallback(
    (groupId: string | null) => {
      if (groupId === null) return;
      else change({ group: groupId });
    },
    [change],
  );

  const editQuestionGroupDetails = useCallback(
    (key: "title" | "subText" | "displaySections") => (value: string) => {
      if (!currentGroup) return;

      currentGroup[key] = value as never;
      setItem({ ...item });
      editPage(item.id, currentGroup.id, { [key]: value });
    },
    [currentGroup, editPage, item],
  );

  const insertGroup = useCallback(() => {
    const idx = item.groups.length;
    const group = {
      id: v4(),
      partOrder: idx,
      questionnaireId: item.id,
      questions: [],
      ruleGroups: [],
      subText: "",
      title: `New Group ${Date.now()}`,
    };

    insert<QuestionnaireQuestionGroupEntity>(item.groups, group, idx);
    addPage(item.id, { title: group.title, id: group.id, partOrder: group.partOrder });
    changeGroup(group.id);
    setItem(item);
  }, [item, addPage, changeGroup]);

  const removeGroup = useCallback(
    (groupId: string) => {
      if (groupId === undefined) return;
      const groupIdx = item.groups.findIndex(g => g.id === groupId);
      remove<QuestionnaireQuestionGroupEntity>(item.groups, groupIdx);
      setItem({ ...item });
      deletePage(item.id, groupId);
      if (item.groups.length) {
        defer(() => changeGroup(item.groups[groupIdx === 0 ? 0 : groupIdx - 1].id));
      } else {
        defer(() => changeGroup(null));
      }
    },
    [changeGroup, deletePage, item],
  );

  const insertQuestion = useCallback(
    (groupId: string, parentId?: string) => {
      const group = item.groups.find(gr => gr.id === groupId);

      if (!group) return;

      const question: Question = {
        autocompleteIntegration: null,
        format: null,
        id: v4(),
        kind: Val.String,
        label: `New Question - ${Date.now()}`,
        placeholder: "",
        subText: "",
        tooltip: "",
        values: [],
      };

      const partOrder = parentId ? group.questions.filter(q => q.parentId === parentId).length : group.questions.length;

      group.questions.push({
        groupId: group.id,
        question,
        questionId: question.id,
        ruleGroups: [],
        id: question.id,
        partOrder: partOrder,
        questionnaireId: item.id,
        parentId,
      } as never);

      addQuestion(item.id, group.id, group.questions.length, parentId, question);
      setItem({ ...item });
    },
    [addQuestion, item],
  );

  const questionId = useMemo(() => search.get("questionId"), [search]);

  const selectedQuestion = useMemo(() => {
    return questions.find(q => q.id === questionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, item, questions]);

  const selectedCarrier = useMemo(() => {
    return item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
  }, [item, carrierId]);

  const removeQuestion = useCallback(
    (groupId: string, questionId: string) => {
      const group = item.groups.find(gr => gr.id === groupId);

      if (!group) return;

      if (questionId === selectedQuestion?.id) {
        activateGroupPart({
          groupPart: "g-details",
        });
      }

      const children = group.questions.filter(v => v.parentId === questionId);

      removeQuestionR(item.id, group.id, questionId);

      remove(
        group?.questions,
        group.questions.findIndex(q => q.id === questionId),
      );

      for (const question of children) {
        removeQuestionR(item.id, group.id, question.id);
        remove(
          group.questions,
          group.questions.findIndex(q => q.id === question.id),
        );
      }

      setItem({ ...item });
    },
    [activateGroupPart, item, removeQuestionR, selectedQuestion],
  );

  const openQuestion = useCallback(
    (groupId: string, id: string) => {
      search.delete("carrier");

      search.set("questionId", id);
      search.set("group", groupId);
      search.set("groupPart", "g-questions");
      change(search);
    },
    [search, change],
  );

  const changeQuestionUIDetails = useCallback(
    (key: keyof Question) => (value: Question[typeof key]) => {
      if (!selectedQuestion || !selectedQuestion.question) return;
      selectedQuestion.question[key] = value as never;
      if (key !== "values") editReferentQuestion(selectedQuestion.questionId, { [key]: value });
      setItem({ ...item });
    },
    [editReferentQuestion, item, selectedQuestion],
  );

  const resolveGroups = useCallback(
    (owner: RuleGroupOwner, ownerId?: string): RuleGroupEntity[] => {
      switch (owner) {
        case "knockout":
          return selectedCarrier?.rules || [];
        case "questionGroup": {
          return currentGroup?.ruleGroups || [];
        }
        case "question": {
          if (selectedQuestion) return selectedQuestion.ruleGroups;
          break;
        }
        case "optionValue": {
          console.log(selectedQuestion?.question?.values?.find(v => v.id === ownerId));
          return selectedQuestion?.question?.values?.find(v => v.id === ownerId)?.visibility || [];
          break;
        }
      }

      return [];
    },
    [currentGroup, selectedQuestion, selectedCarrier],
  );

  const insertRuleGroup = useCallback(
    (owner: RuleGroupOwner, kind: RuleGroupKind, ownerId?: string, preDefinedGroup?: RuleGroupEntity) => {
      if (!currentGroup) return;
      let group: RuleGroupEntity;
      const groups = kind === RuleGroupKind.LockAnswer ? selectedQuestion?.lockAnswerRules || [] : resolveGroups(owner, ownerId);

      if (preDefinedGroup) {
        group = preDefinedGroup;
      } else {
        const rule: Rule =
          kind === RuleGroupKind.PreFill
            ? {
              value: {
                id: v4(),
                fn: ValueFunctions.Evaluate,
                args: ["True"],
              } as never,
              op: ComplexOperator.And,
            }
            : {
              value: {
                id: v4(),
                fn: ValueFunctions.Is,
                args: [],
              } as never,
              op: ComplexOperator.And,
            };

        let actionKind: RuleGroupActionMetadata;
        switch (kind) {
          case RuleGroupKind.Visibility:
            actionKind = QuestionVisibility.Hidden;
            break;
          case RuleGroupKind.Pre:
            actionKind = {
              kind: QuestionActionKind.JumpTo,
              value: "",
            };
            break;
          case RuleGroupKind.Post:
            actionKind = {
              kind: QuestionActionKind.JumpTo,
              value: "",
            };
            break;
          case RuleGroupKind.Knockout:
          case RuleGroupKind.Validation:
            actionKind = {
              message: "",
              alertLevel: ValidationAlertLevel.Error,
            };
            break;
          case RuleGroupKind.PreFill:
            actionKind = {
              id: v4(),
              args: [],
              fn: ValueFunctions.Value,
            } as never;
            break;
          case RuleGroupKind.LockAnswer:
            actionKind = null;
        }

        group = {
          name: "",
          actionKind,
          op: ComplexOperator.Standalone,
          id: v4(),
          kind,
          partOrder: groups.length,
          rules: [rule],
        };
      }

      group.partOrder = groups.length;

      groups.push(group as never);

      if (owner === "question") {
        addQuestionRuleGroup(item.id, currentGroup.id, selectedQuestion?.id as string, group);
      } else if (owner === "optionValue") {
        addReferentQuestionValueVisibilityGroup(selectedQuestion?.questionId as string, ownerId as string, group);
      }

      setItem({ ...item });
    },
    [addQuestionRuleGroup, currentGroup, item, resolveGroups, selectedQuestion],
  );
  const removeRuleGroup = useCallback(
    (owner: RuleGroupOwner, kind: RuleGroupKind, ownerId?: string) => (id: string) => {
      const groups = kind === RuleGroupKind.LockAnswer ? selectedQuestion?.lockAnswerRules || [] : resolveGroups(owner, ownerId);
      const group = groups.find(g => g.id === id);

      if (!group) return;

      if (owner === "question") {
        removeQuestionRuleGroup(item.id, currentGroup?.id as string, selectedQuestion?.id as string, group.id, kind);
      } else if (owner === "knockout") {
        removeKnockoutRule(item.id, carrierId as string, group.id);
      } else if (owner === "optionValue") {
        removeReferentQuestionValueVisibilityGroup(selectedQuestion?.questionId as string, ownerId as string, group.id);
      }
      remove(
        groups,
        groups.findIndex(g => g.id === group.id),
      );
      setItem({ ...item });
    },
    [carrierId, currentGroup, item, removeKnockoutRule, removeQuestionRuleGroup, resolveGroups, selectedQuestion],
  );
  const updateRuleGroup = useCallback(
    (owner: RuleGroupOwner, group: RuleGroupEntity, key: keyof RuleGroupEntity, ownerId?: string) =>
      (val: RuleGroupEntity[typeof key]) => {
        group[key] = val as never;

        if (owner === "knockout" && selectedCarrier) {
          group.kind = RuleGroupKind.Knockout;
          editKnockoutRule(item.id, selectedCarrier?.carrierId, group.id, { [key]: val, kind: group.kind });
        } else if (owner === "optionValue") {
          editReferentQuestionValueVisibilityGroup(
            selectedQuestion?.questionId as string,
            ownerId as string,
            group.id as string,
            group,
          );
        } else {
          editQuestionRuleGroup(item.id, currentGroup?.id as string, selectedQuestion?.id as string, group.id, group);
        }
        setItem({ ...item });
      },
    [currentGroup, selectedCarrier, item, editKnockoutRule, editQuestionRuleGroup, selectedQuestion?.id],
  );

  const insertRule = useCallback(
    (owner: RuleGroupOwner, group: RuleGroupEntity, ownerId?: string) => {
      group.rules.push({
        op: group.rules.length ? ComplexOperator.And : null,
        id: v4(),
        value: {
          id: v4(),
          fn: ValueFunctions.Is,
          args: [],
        } as never,
      });

      if (owner === "optionValue") {
        editReferentQuestionValueVisibilityGroup(
          selectedQuestion?.questionId as string,
          ownerId as string,
          group.id as string,
          group,
        );
      } else if (group.kind === RuleGroupKind.Knockout) {
        editKnockoutRule(item.id, carrierId as string, group.id, group);
      } else {
        editQuestionRuleGroup(item.id, currentGroup?.id as string, selectedQuestion?.id as string, group.id, group);
      }

      setItem({ ...item });
    },
    [carrierId, currentGroup?.id, item, selectedQuestion?.id, updateKnockoutRuleRules, updateQuestionRuleGroupRules],
  );

  const removeRule = useCallback(
    (owner: RuleGroupOwner, group: RuleGroupEntity, ruleId: string, ownerId?: string) => {
      remove(
        group.rules,
        group.rules.findIndex(r => r.id === ruleId),
      );

      if (owner === "optionValue") {
        editReferentQuestionValueVisibilityGroup(
          selectedQuestion?.questionId as string,
          ownerId as string,
          group.id as string,
          group,
        );
      } else if (group.kind === RuleGroupKind.Knockout) {
        editKnockoutRule(item.id, carrierId as string, group.id, group);
      } else {
        editQuestionRuleGroup(item.id, currentGroup?.id as string, selectedQuestion?.id as string, group.id, group);
      }

      setItem({ ...item });
    },
    [carrierId, currentGroup, item, selectedQuestion, updateKnockoutRuleRules, updateQuestionRuleGroupRules],
  );

  const editRule = useCallback(
    (owner: RuleGroupOwner, group: RuleGroupEntity, ownerId?: string) =>
      (rule: Rule, key: keyof Rule) =>
        (val: Rule[typeof key]) => {
          rule[key] = val as never;

          if (owner === "optionValue") {
            editReferentQuestionValueVisibilityGroup(
              selectedQuestion?.questionId as string,
              ownerId as string,
              group.id as string,
              group,
            );
          } else if (owner === "knockout") {
            group.kind = RuleGroupKind.Knockout;
            editKnockoutRule(item.id, carrierId as string, group.id, group);
          } else {
            editQuestionRuleGroup(item.id, currentGroup?.id as string, selectedQuestion?.id as string, group.id, group);
          }

          setItem({ ...item });
        },
    [carrierId, currentGroup, item, selectedQuestion, updateKnockoutRuleRules, updateQuestionRuleGroupRules],
  );

  const context = useCallback((): QuestionContext => {
    return {
      me: {
        groupIdx: item.groups.findIndex(g => g.id === groupId),
        questionIdx: currentGroup?.questions?.findIndex(q => q.id === questionId) || 0,
      },
      questionnaire: item,
    };
  }, [item, currentGroup, groupId, questionId]);

  const insertCarrierMapping = useCallback(
    (data: Pick<CarrierQuestionnaire, "carrierId" | "carrierName">) => {
      if (!item.carrierQuestionnaires) {
        item.carrierQuestionnaires = [];
      }

      item.carrierQuestionnaires.push({ ...data, rules: [], defaults: [], questions: [] });
      setItem({ ...item });
    },
    [item],
  );

  const setCarrierMapping = useCallback(
    (carrierId: string, data: Pick<CarrierQuestionnaire, "carrierId" | "carrierName">) => {
      const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
      if (!carrier) return;
      Object.assign(carrier, data);
      setItem({ ...item });
    },
    [item],
  );

  const removeCarrierMapping = useCallback(
    (carrierId: string) => {
      remove(
        item.carrierQuestionnaires,
        item.carrierQuestionnaires.findIndex(c => c.carrierId === carrierId),
      );
      setItem({ ...item });
    },
    [item],
  );

  const insertCarrierMappingItem = useCallback(
    (carrierId: string, part: CarrierMappingPart, referenceValueId?: string) => {
      const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
      if (!carrier) return;
      switch (part) {
        case "defaults": {
          let defaults: CarrierDefault = {
            id: v4(),
            jsonNode: [],
            label: "",
            isList: false,
            value: { kind: Val.String, value: "" },
          };
          carrier.defaults.push(defaults);
          addCarrierDefault(item.id, carrierId, defaults);
          defer(() => {
            activateGroupPart({ carrier: carrierId, groupPart: "c-defaults", dataMapId: defaults.id });
          }, 1000);
        }

          break;
        case "questions": {
          const dataMap: CarrierQuestion = {
            id: v4(),
            jsonNode: [],
            isList: false,
            label: "",
            referenceValueId,
            valueRules: [{ id: v4(), fn: ValueFunctions.Value, args: [], conditions: [] } as never],
          } as never;
          carrier.questions.push(dataMap);
          addCarrierDataMap(item.id, carrierId, dataMap);
          defer(() => {
            activateGroupPart({ carrier: carrierId, groupPart: "c-questions", dataMapId: dataMap.id });
          }, 1000);
        }

          break;
        case "rules": {
          const rule: RuleGroupEntity<QuestionValidityAction> = {
            id: v4(),
            actionKind: { alertLevel: ValidationAlertLevel.Error, message: "Declination" },
            op: ComplexOperator.Standalone,
            kind: RuleGroupKind.Knockout,
            name: `Knockout Rule ${carrier.rules.length}`,
            partOrder: carrier.rules.length,
            rules: [
              {
                op: ComplexOperator.Standalone,
                value: {
                  fn: ValueFunctions.Is,
                  args: [],
                },
              },
            ],
          };
          carrier.rules.push(rule);
          addKnockoutRule(item.id, carrierId, rule);
          defer(() => {
            activateGroupPart({ carrier: carrierId, groupPart: "c-knockout", dataMapId: rule.id });
          }, 1000);
        }

          break;
      }
      setItem({ ...item });
    },
    [activateGroupPart, addCarrierDataMap, addCarrierDefault, addKnockoutRule, item],
  );

  const setCarrierMappingItem = useCallback(
    (
      carrierId: string,
      idx: number,
      data: ["questions", CarrierQuestion] | ["defaults", CarrierDefault] | ["rules", RuleGroupEntity<QuestionAction>],
    ) => {
      const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
      if (!carrier) return;
      const [part, patch] = data;
      switch (part) {
        case "questions": {
          carrier.questions[idx] = patch;
          const { valueRules, jsonNode, referencedQuestionId } = patch;
          editCarrierDataMap(item.id, carrier.carrierId, carrier.questions[idx].id, {
            valueRules,
            jsonNode,
            referencedQuestionId,
          });
        }
          break;
        case "defaults": {
          carrier.defaults[idx] = patch;
          const { jsonNode, value, isList } = patch;
          editCarrierDefault(item.id, carrier.carrierId, carrier.defaults[idx].id, {
            jsonNode,
            value,
            isList,
          } as never);
        }
          break;
      }

      setItem({ ...item });
    },
    [editCarrierDataMap, editCarrierDefault, item],
  );

  const removeCarrierMappingItem = useCallback(
    (carrierId: string, id: string, part: CarrierMappingPart) => {
      const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
      if (!carrier) return;
      switch (part) {
        case "defaults": {
          const idx = carrier.defaults.findIndex(d => d.id === id);
          removeCarrierDefault(item.id, carrierId, id);
          remove(carrier.defaults, idx);
        }
          break;
        case "questions": {
          const idx = carrier.questions.findIndex(d => d.id === id);
          removeCarrierDataMap(item.id, carrierId, id);
          remove(carrier.questions, idx);
        }
          break;
        case "rules": {
          const idx = carrier.rules.findIndex(d => d.id === id);
          removeKnockoutRule(item.id, carrierId, id);
          remove(carrier.rules, idx);
        }

          break;
      }

      setItem({ ...item });
    },
    [item, removeCarrierDataMap, removeCarrierDefault, removeKnockoutRule],
  );

  const bulkSetCarrierMapping = useCallback(
    (carrierId: string, mapping: CarrierQuestion[]) => {
      const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);

      if (carrier) {
        carrier.questions = mapping;
      }

      bulkAddCarrierDataMap(item.id, carrierId, { items: mapping });

      setItem({ ...item });
    },
    [item, bulkAddCarrierDataMap],
  );

  const bulkSetCarrierDefaults = useCallback(
    (carrierId: string, mapping: CarrierDefault[]) => {
      const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);

      if (carrier) {
        carrier.defaults = mapping;
      }

      bulkAddCarrierDefaults(item.id, carrierId, { items: mapping });

      setItem({ ...item });
    },
    [item, bulkAddCarrierDefaults],
  );

  const bulkSetKnockoutRules = useCallback((carrierId: string, rules: RuleGroupEntity<QuestionValidityAction>[]) => {
    const carrier = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);

    if (carrier) {
      carrier.rules = rules;
    }

    bulkAddCarrierKnockoutRules(item.id, carrierId, { items: rules });

    setItem({ ...item });
  }, [item, bulkAddCarrierKnockoutRules]);

  const addSection = useCallback(() => {
    if (!currentGroup) return;

    if (!currentGroup.sections) {
      currentGroup.sections = [];
    }

    const sections = currentGroup.sections;
    const section = {
      id: v4(),
      title: `Section ${Date.now()}`,
      partOrder: sections.length || 0,
      questions: [],
      display: DisplayDirection.Col,
    };

    sections.push(section);

    addSectionR(item.id, currentGroup.id, {
      display: section.display,
      id: section.id,
      partOrder: section.partOrder,
      title: section.title,
    });
    setItem({ ...item });
  }, [addSectionR, currentGroup, item]);

  const removeSection = useCallback(
    (sectionId: string) => {
      if (!currentGroup) return;

      currentGroup.questions
        .filter(q => q.sectionId === sectionId)
        .forEach(q => {
          q.sectionId = undefined;
        });

      remove(currentGroup.sections || [], currentGroup.sections?.findIndex(v => v.id === sectionId) || 0);

      deleteSection(item.id, currentGroup.id, sectionId);

      setItem({ ...item });
    },
    [currentGroup, deleteSection, item],
  );
  const updateSection = useCallback(
    (sectionId: string) => (key: keyof QuestionnaireGroupSection) => (v: QuestionnaireGroupSection[typeof key]) => {
      if (!currentGroup) return;

      const sections = currentGroup.sections;
      const sec = sections?.find(v => v.id === sectionId);
      if (!sec) return;

      sec[key] = v as never;

      editSection(item.id, currentGroup.id, sec.id, { [key]: v });
      setItem({ ...item });
    },
    [currentGroup, editSection, item],
  );

  const updateQuestion = useCallback(
    (key: keyof QuestionnaireQuestionEntity) => (value: string | number | null | string[]) => {
      if (!selectedQuestion || !currentGroup) return;

      selectedQuestion[key] = value as never;
      editQuestion(item.id, currentGroup.id, selectedQuestion.id, {
        [key]: key === "showForCarriers" ? ((value as string[]) || []).join(",") : value,
      });

      setItem({ ...item });
    },
    [selectedQuestion, currentGroup, editQuestion, item],
  );

  useEffect(() => {
    getMappings(item.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mappingsResponse?.ok) {
      const data: CarrierQuestionnaire[] = mappingsResponse.data.map(cq => ({
        ...cq,
        questions: cq.questions.map(q => ({
          ...q,
          valueRules: q.valueRules.map(v => stringToOp(v as never)),
        })),
        rules: cq.rules.map(r => {
          return {
            ...r,
            ...stringToRuleGroup(r.rules as never)[0],
          };
        }),
      }));
      setItem({ ...item, carrierQuestionnaires: data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id, mappingsResponse?.ok]);

  return (
    <BuilderNGNCtx.Provider
      value={{
        item,
        editQuestionnaireDetails,
        insertGroup,
        changeGroup,
        currentGroup,
        groupId,
        removeGroup,
        activateGroupPart,
        groupPartActive,
        editQuestionGroupDetails,
        insertQuestion,
        removeQuestion,
        openQuestion,
        selectedQuestion,
        changeQuestionUIDetails,
        insertRuleGroup,
        removeRuleGroup,
        updateRuleGroup,
        insertRule,
        editRule,
        removeRule,
        context,
        insertCarrierMapping,
        setCarrierMapping,
        removeCarrierMapping,
        insertCarrierMappingItem,
        setCarrierMappingItem,
        removeCarrierMappingItem,
        carrierId,
        carrierMappingRuleId,
        carrierMappingDefaultId,
        carrierMappingQuestionId,
        removeSection,
        addSection,
        updateSection,
        resort,
        updateQuestion,
        selectedCarrier,
        bulkSetCarrierMapping,
        questions,
        bulkSetCarrierDefaults,
        bulkUpdateQuestion,
        bulkUpdateGroup,
        bulkSetKnockoutRules,
      }}
    >
      {children}
    </BuilderNGNCtx.Provider>
  );
};

import { CustomerRecord, QuestionnaireState, QuestionnaireSubmissionStatus } from "../../types/customer-record";
import { formatDate, isQuoteCompleted } from "./helpers";
import { DocumentChartBarIcon, UserGroupIcon, UserIcon } from "@heroicons/react/24/outline";

interface CustomerTableProps {
  customerRecords: CustomerRecord[];
  onQuoteClick: (questionnaireStateId: string) => void;
  onBranchClick: (branch: { id: string, name: string }) => void;
  onUserClick: (user: { id: string, username: string }) => void;
}

interface ActionIconProps {
  icon: React.ReactElement;
  tooltip: string;
  onClick: () => void;
}

const ActionIcon = ({ icon, tooltip, onClick }: ActionIconProps) => (
  <div className="relative group">
    <button
      onClick={onClick}
      className="p-1 text-gray-400 hover:text-indigo-600 rounded-full hover:bg-gray-50 transition-colors"
    >
      {icon}
    </button>
    <div
      className="absolute z-10 invisible group-hover:visible bg-gray-900 text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2 whitespace-nowrap">
      {tooltip}
      <div className="absolute top-full left-1/2 transform -translate-x-1/2">
        <div className="w-2 h-2 bg-gray-900 transform rotate-45" />
      </div>
    </div>
  </div>
);

export const CustomerTable = ({
                                customerRecords,
                                onQuoteClick,
                                onBranchClick,
                                onUserClick,
                              }: CustomerTableProps) => {
  // Flatten the customer records to create rows for each questionnaire state
  const tableRows = customerRecords.flatMap(record =>
    record.questionnaireState?.map((state, index) => ({
      ...record,
      questionnaireState: state,
    })) || [],
  );

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full">
        <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Business Name
          </th>
   
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Agent
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Branch
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Last Updated
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Max Coverage Amount
                      </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Sucess Rate
                      </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Actions
          </th>
        </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {tableRows.map((row, index) => (
          <tr
            key={`${row.id}-${row.questionnaireState.id}`}
            className={`hover:bg-gray-50 `}
          >
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900">
                {row.businessName}
              </div>
            </td>
            
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {row.user?.username}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {row.agencyBranch?.name}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <StatusBadge status={getLatestStatus(row.questionnaireState)} />
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {formatDate(row.updatedAt || row.questionnaireState.updatedAt || "N/A")}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {getQuoteValue(row.questionnaireState)}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {getSuccessRate(row.questionnaireState)}
              </div>
            </td>   
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center space-x-3">
                <ActionIcon
                  icon={<DocumentChartBarIcon className="h-5 w-5" />}
                  tooltip="View Quote Report"
                  onClick={() => onQuoteClick(row.questionnaireState.id)}
                />
                {row.agencyBranch?.id && (
                  <ActionIcon
                    icon={<UserGroupIcon className="h-5 w-5" />}
                    tooltip="View Branch Report"
                    onClick={() => onBranchClick(row.agencyBranch)}
                  />
                )}
                {row.user?.id && (
                  <ActionIcon
                    icon={<UserIcon className="h-5 w-5" />}
                    tooltip="View User Report"
                    onClick={() => onUserClick(row.user)}
                  />
                )}
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "Completed":
        return "bg-green-100 text-green-800";
      case "Started":
        return "bg-yellow-100 text-yellow-800";
      case "Errored":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(status)}`}>
            {status || "Not Submitted"}
        </span>
  );
};

const getLatestStatus = (questionnaireState: QuestionnaireState): string => {
  const latestSubmission = questionnaireState.submissions.find(v => v.status === "Completed") || questionnaireState.submissions.find(v => v.status === "Errored");
  return latestSubmission?.status ?? "Not Submitted";
};

const getQuoteValue = (questionnaireState: QuestionnaireState): string => {
  const highestQuoteValue = Math.max(
    ...questionnaireState.submissions.map(s => s.coverage?.totalAmount ?? 0),
  );

  return highestQuoteValue > 0
    ? `$${highestQuoteValue.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    : "N/A";
}; 

const getSuccessRate = (questionnaireState: QuestionnaireState): string => {
  const totalSubmissions = questionnaireState.submissions.length;
  const successfulSubmissions = questionnaireState.submissions.filter(isQuoteCompleted).length;
  return totalSubmissions > 0 ? `${((successfulSubmissions / totalSubmissions) * 100).toFixed(2)}%` : "N/A";
};
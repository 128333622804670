interface DateRangePickerProps {
    from: Date;
    to: Date;
    onChange: (range: { from: Date; to: Date }) => void;
}

export const DateRangePicker = ({ from, to, onChange }: DateRangePickerProps) => {
    return (
        <div className="flex items-center gap-4 justify-end">
            <div>
                <label className="block text-sm font-medium text-gray-700">From</label>
                <input
                    type="date"
                    value={from.toISOString().split('T')[0]}
                    onChange={(e) => onChange({ 
                        from: new Date(e.target.value), 
                        to 
                    })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">To</label>
                <input
                    type="date"
                    value={to.toISOString().split('T')[0]}
                    onChange={(e) => onChange({ 
                        from, 
                        to: new Date(e.target.value) 
                    })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
            </div>
        </div>
    );
}; 
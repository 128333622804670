import { useBusinessReportsState } from "../../state/BusinessReportsState";

interface AgencySelectorProps {
    onAgencyChange: (agencyId: string | null) => void;
}

export const AgencySelector = ({ onAgencyChange }: AgencySelectorProps) => {
    const { state: { availableAgencies } } = useBusinessReportsState();
    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="agency" className="text-sm font-medium text-gray-700">Agency</label>
            <select 
                id="agency"
                className="block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 h-11"
                onChange={(e) => onAgencyChange(e.target.value || null)}
                defaultValue=""
            >
                <option value="">Select Agency</option>
                {availableAgencies.map((agency) => (
                    <option key={agency.id} value={agency.id}>{agency.name}</option>
                ))}
            </select>
        </div>
    );
}; 
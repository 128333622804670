import { useState } from "react";
import { QuestionnaireState, CustomerRecord } from "../../types/customer-record";
import { JsonViewer } from "../util/JsonViewer";
import { useAppState } from "../../state/State";
import { CoverageDetails } from '../QuoteManagement/CoverageDetails';

interface QuoteDetailsProps {
    questionnaireState: QuestionnaireState;
    customerInfo?: CustomerRecord; // Optional customer info for context
    handleClose: () => void;
}

export const QuoteDetails = ({ 
    questionnaireState, 
    customerInfo, 
    handleClose 
}: QuoteDetailsProps) => {
    const [activeTab, setActiveTab] = useState<"info" | "questionnaire" | "submissions">("info");
    const { state: { products, carriers } } = useAppState();

    const formatDate = (date: string) => {
        return new Date(date).toLocaleString();
    };

    const renderAnswerValue = (value: any) => {
        if (Array.isArray(value)) {
            if (value.length === 0) return <span className="text-secondary-text">No data</span>;

            if (typeof value[0] === "object") {
                return (
                    <div className="pl-4">
                        {value.map((item, index) => (
                            <div key={index} className="bg-white rounded-md">
                                {Object.entries(item).map(([subKey, subAnswer]) => (
                                    <div key={subKey} className="ml-2 border-l-2 border-secondary-light pl-3 py-1 flex flex-1">
                                        <p className="text-sm text-secondary-text font-bold w-20">{subKey}</p>
                                        <div className="text-md text-dark-blue">
                                            {renderAnswerValue((subAnswer as any)?.value)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                );
            }

            return <span className="text-dark-blue">{value.join(", ")}</span>;
        }

        if (value === null || value === undefined) {
            return <span className="text-secondary-text italic">Not provided</span>;
        }

        if (typeof value === "boolean") {
            return (
                <span className={`inline-flex items-center px-2 py-1 rounded-full text-sm ${
                    value ? "bg-light-green text-dark-green" : "bg-danger-light text-danger"
                }`}>
                    {value ? "Yes" : "No"}
                </span>
            );
        }

        if (typeof value === "number") {
            return <span className="font-mono text-dark-blue">{value.toLocaleString()}</span>;
        }

        // Check if it's a date string
        if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}/)) {
            return <span className="text-dark-blue">{new Date(value).toLocaleDateString()}</span>;
        }

        return <span className="text-dark-blue">{value.toString()}</span>;
    };

    const renderCustomerInfo = () => (
        <div className="space-y-6">
            {customerInfo ? (
                <>
                    {/* Business Info Card */}
                    <div className="bg-white rounded-lg shadow-sm p-6">
                        <h3 className="text-lg font-semibold text-dark-blue mb-4">Business Information</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm text-secondary-text">Business Name</p>
                                <p className="text-md text-dark-blue font-medium">{customerInfo.businessName}</p>
                            </div>
                            <div>
                                <p className="text-sm text-secondary-text">Business Type</p>
                                <p className="text-md text-dark-blue">{customerInfo.businessType.title}</p>
                            </div>
                            <div>
                                <p className="text-sm text-secondary-text">NAICS</p>
                                <p className="text-md text-dark-blue">
                                    {customerInfo.businessType.naicsCode} - {customerInfo.businessType.naicsTitle}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Contact Info Card */}
                    <div className="bg-white rounded-lg shadow-sm p-6">
                        <h3 className="text-lg font-semibold text-dark-blue mb-4">Contact Information</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm text-secondary-text">Contact Name</p>
                                <p className="text-md text-dark-blue">
                                    {[
                                        customerInfo.firstName,
                                        customerInfo.middleInitial,
                                        customerInfo.lastName,
                                        customerInfo.suffix,
                                    ].filter(Boolean).join(" ")}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-secondary-text">Email</p>
                                <p className="text-md text-dark-blue">{customerInfo.emailAddress}</p>
                            </div>
                            <div>
                                <p className="text-sm text-secondary-text">Phone</p>
                                <p className="text-md text-dark-blue">
                                    {customerInfo.phoneNumber}
                                    {customerInfo.phoneExt && ` ext. ${customerInfo.phoneExt}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <h3 className="text-lg font-semibold text-dark-blue mb-4">Quote Information</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="text-sm text-secondary-text">Quote ID</p>
                            <p className="text-md text-dark-blue font-mono">{questionnaireState.id}</p>
                        </div>
                        <div>
                            <p className="text-sm text-secondary-text">Created At</p>
                            <p className="text-md text-dark-blue">{formatDate(questionnaireState.createdAt)}</p>
                        </div>
                        <div>
                            <p className="text-sm text-secondary-text">Last Updated</p>
                            <p className="text-md text-dark-blue">{formatDate(questionnaireState.updatedAt)}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const renderQuestionnaire = () => (
        <div className="space-y-4">
            {Object.entries(questionnaireState.answers).map(([key, answer]) => (
                <div key={key} className="border-b border-light pb-3">
                    <div className="flex items-start justify-between">
                        <div className="flex flex-1">
                            <p className="text-secondary-text font-bold w-24">{key}</p>
                            <div className="text-md">
                                {renderAnswerValue(answer?.value)}
                            </div>
                        </div>
                        {answer?.multiple && (
                            <span className="text-xs bg-light text-secondary-text px-2 py-1 rounded-full">
                                Multiple
                            </span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );

    const renderSubmissions = () => {
        return (
            <div className="space-y-4">
                {questionnaireState.submissions.map((submission) => {
                    const product = products.find(p => p.id === submission.questionnaireCarrierProduct.lineOfBusinessId);
                    const carrier = carriers.find(c => c.id === submission.carrierId);

                    return (
                        <div key={submission.id} className="bg-white rounded-lg shadow-sm p-6">
                            <div className="flex items-center justify-between mb-4">
                                <div className="space-y-2">
                                    <h3 className="text-lg text-secondary">
                                        {carrier?.name}
                                    </h3>
                                    <div className="flex items-center gap-2">
                                        <span className="px-3 py-1 bg-light rounded-full text-sm text-primary font-medium">
                                            {product?.name}
                                        </span>
                                        <span className={`px-3 py-1 rounded-full text-sm ${
                                            submission.status === "Completed" ? "bg-light-green text-dark-green" :
                                            submission.status === "Errored" ? "bg-danger-light text-danger" :
                                            "bg-light text-secondary-text"
                                        }`}>
                                            {submission.status}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4 text-sm mb-4">
                                <div>
                                    <p className="text-secondary-text">Updated At</p>
                                    <p className="text-dark-blue">{formatDate(submission.updatedAt)}</p>
                                </div>
                            </div>

                           

                            {submission.questionnaireSubmissionMeta && (
                                <div className="mt-4">
                                    <h4 className="text-md font-medium text-dark-blue mb-2">Carrier Response</h4>
                                    <JsonViewer
                                        data={submission.questionnaireSubmissionMeta.data}
                                        initialExpanded={false}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="fixed inset-y-0 right-0 w-[70vw] max-w-[1024px] min-w-[480px] bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50">
            <div className="h-full flex flex-col">
                {/* Header */}
                <div className="p-4 border-b border-light">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold text-dark-blue">Quote Details</h2>
                        <button onClick={handleClose} className="p-2 hover:bg-light rounded-full transition-colors">
                            <svg className="w-5 h-5 text-secondary" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>

                    {/* Tabs */}
                    <div className="flex space-x-4 border-b border-light">
                        {(["info", "questionnaire", "submissions"] as const).map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`pb-2 px-1 text-sm font-medium transition-colors ${
                                    activeTab === tab
                                        ? "text-primary border-b-2 border-primary"
                                        : "text-secondary-text hover:text-primary"
                                }`}
                            >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-y-auto p-4">
                    {activeTab === "info" && renderCustomerInfo()}
                    {activeTab === "questionnaire" && renderQuestionnaire()}
                    {activeTab === "submissions" && renderSubmissions()}
                </div>
            </div>
        </div>
    );
}; 
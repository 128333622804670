import { useEffect, useState } from 'react';
import { DateRangePicker } from './DateRangePicker';
import { AgencySelector } from './AgencySelector';
import { PerformanceMetrics } from './PerformanceMetrics';
import { CustomerTable } from './CustomerTable';
import { CustomerRecord, QuestionnaireState } from '../../types/customer-record';
import { useBusinessReportsState } from '../../state/BusinessReportsState';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { QuoteReport } from './QuoteReport';
import { LoadingScreen } from '../shared/LoadingScreen';

const SelectedFilter = ({ 
    label, 
    value, 
    onClear 
}: { 
    label: string; 
    value: string; 
    onClear: () => void;
}) => (
    <div className="flex items-center bg-indigo-50 rounded-lg px-4 py-2 text-sm">
        <div className="mr-2">
            <span className="text-gray-500 font-medium">{label}:</span>
            <span className="ml-2 text-indigo-700">{value}</span>
        </div>
        <button
            onClick={onClear}
            className="ml-2 text-indigo-400 hover:text-indigo-600 p-1 rounded-full hover:bg-indigo-100 transition-colors"
        >
            <XMarkIcon className="h-4 w-4" />
        </button>
    </div>
);

export const BusinessReports = () => {
    const [selectedAgency, setSelectedAgency] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState({
        from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        to: new Date()
    });

    const [selectedBranch, setSelectedBranch] = useState<{id: string, name: string} | null>(null);
    const [selectedUser, setSelectedUser] = useState<{id: string, username: string} | null>(null);

    const { state: { businessReports, quoteDetails }, getBusinessReportsR, getAvailableAgenciesR, getQuoteDetailsR, clearQuoteDetails } = useBusinessReportsState();
    
    const handleQuoteClick = (state: string) => {
        getQuoteDetailsR(state);
        
    };

    const handleBranchClick = (branch: {id: string, name: string}) => {
        setSelectedBranch(branch);
    };

    const handleUserClick = (user: {id: string, username: string}) => {
        setSelectedUser(user);
    };
    
    useEffect(() => {
        getAvailableAgenciesR();
    }, []);

    useEffect(() => {
        if (selectedAgency) {
            getBusinessReportsR(selectedAgency, dateRange.from.toISOString(), dateRange.to.toISOString(), selectedBranch?.id, selectedUser?.id);
        }
    }, [selectedAgency, dateRange, selectedBranch, selectedUser]);  


    return (
        <>
            <div className="p-6">
                <div className="mb-6">
                    
                    {/* Filters Section */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <AgencySelector 
                                onAgencyChange={setSelectedAgency}
                            />
                            <DateRangePicker
                                from={dateRange.from}
                                to={dateRange.to}
                                onChange={setDateRange}
                            />
                        </div>

                        {/* Active Filters Display */}
                        {(selectedBranch || selectedUser) && (
                            <div className="border-t border-gray-100 pt-4">
                                <div className="flex flex-wrap items-center gap-3">
                                    <span className="text-sm text-gray-500 font-medium">Active Filters:</span>
                                    {selectedBranch && (
                                        <SelectedFilter
                                            label="Branch"
                                            value={selectedBranch.name}
                                            onClear={() => setSelectedBranch(null)}
                                        />
                                    )}
                                    {selectedUser && (
                                        <SelectedFilter
                                            label="Agent"
                                            value={selectedUser.username}
                                            onClear={() => setSelectedUser(null)}
                                        />
                                    )}
                                    {(selectedBranch || selectedUser) && (
                                        <button
                                            onClick={() => {
                                                setSelectedBranch(null);
                                                setSelectedUser(null);
                                            }}
                                            className="text-sm text-gray-500 hover:text-gray-700 underline"
                                        >
                                            Clear all filters
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Performance Metrics */}
                <PerformanceMetrics 
                    customerRecords={businessReports}
                    className="mb-6"
                />

                {/* Customer Table */}
                <CustomerTable 
                    customerRecords={businessReports || []}
                    onQuoteClick={handleQuoteClick}
                    onBranchClick={handleBranchClick}
                    onUserClick={handleUserClick}
                />

                {/* Quote Report Drawer */}
                {quoteDetails && (
                    <QuoteReport
                        questionnaireState={quoteDetails}
                        onClose={clearQuoteDetails}
                    />
                )}
            </div>
        </>
    );
};
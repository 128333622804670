import { createContext, PropsWithChildren, useContext, useState, useEffect, useMemo } from "react";
import {
  QuestionnaireQuestionGroupEntity,
  QuestionnaireQuestionEntity,
  RuleGroupKind,
  QuestionVisibility,
  QuestionRuleGroupEntity,
 } from "@Savus-Inc/questionnaire-types";
import { useSearchParams } from "react-router-dom";
import { useAppState } from "../../state/State";
import { calculateQuestionExternalIdMap } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { ruleGroupToString } from "@Savus-Inc/dsl/dist/utils/transformer";
import {CarrierFilter, StateFilter, GroupQuestionMap
} from "./DocumentationTypes/QuestionnaireTypes";
import { parseCarriersFromURL, parseStatesFromURL, computeTotalQuestionsMap, updateParam, getFilteredQuestionsMap } from "./utils/questionnaireUtils";

interface DocumentationContextType {
  selectedGroup?: QuestionnaireQuestionGroupEntity;
  selectedQuestion?: QuestionnaireQuestionEntity;
  carriers: string[];
  setSelectedGroup: (group: QuestionnaireQuestionGroupEntity) => void;
  setSelectedQuestion: (question: QuestionnaireQuestionEntity) => void;
  filterCarriers: CarrierFilter[];
  setFilterCarriers: (carriers: CarrierFilter[]) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filteredQuestions: QuestionnaireQuestionEntity[];
  referenceMap: Record<string, string>;
  states: StateFilter[];
  filterStates: StateFilter[];
  setFilterStates: (states: StateFilter[]) => void;
  totalQuestionsMap: GroupQuestionMap; // stores all questions per group
  filterQuestionsMap: GroupQuestionMap; // stores only filtered questions per group (filtered by search term and carriers)
  questionMap: Record<string, QuestionnaireQuestionEntity>; // stores all questions with their externalId as key
}

const DocumentationContext = createContext<DocumentationContextType>({} as DocumentationContextType);

export const useDocumentationContext = () => useContext(DocumentationContext);

export type { StateFilter};

export const DocumentationCTX = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedGroup, setSelectedGroup] = useState<QuestionnaireQuestionGroupEntity>();
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionnaireQuestionEntity>();
  const [filterCarriers, setFilterCarriers] = useState<CarrierFilter[]>([]);
  const [filterStates, setFilterStates] = useState<StateFilter[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState<QuestionnaireQuestionEntity[]>([]);
  const [filteredQuestionsMap, setFilteredQuestionsMap] = useState(new Map());
  const {
    state: { currentQuestionnaire, carriers, states },
  } = useAppState();

  const [isLoading, setIsLoading] = useState(true);

  const [totalQuestionsMap, setTotalQuestionsMap] = useState<GroupQuestionMap>({});
  const [filterQuestionsMap, setFilterQuestionsMap] = useState<GroupQuestionMap>({});

  // Load filters from URL on init
  useEffect(() => {
    // Load search term
    const urlSearchTerm = searchParams.get("search") || "";
     setSearchTerm(urlSearchTerm);

    // Load carriers
    const urlCarriers = searchParams.get("carriers");
    if (urlCarriers) {
        const parsedCarriers = parseCarriersFromURL(urlCarriers);
        setFilterCarriers(parsedCarriers);    
    }

    // Load states
    const urlStates = searchParams.get("states");
    if (urlStates) {
        const selectedStates = parseStatesFromURL(urlStates, states);
        setFilterStates(selectedStates);
    }

    // If there's a question ID in the URL, find and select it
    const questionId = searchParams.get("questionId");
    if (questionId) {
      const question = currentQuestionnaire?.groups.flatMap(group => group.questions).find(q => q.question?.externalId === questionId);
      if (question) {
        setSelectedQuestion(question);
      } else {
        setSelectedQuestion(undefined);
      }
    } else {
      setSelectedQuestion(undefined);
    }
    setIsLoading(false);
  }, [searchParams, states, currentQuestionnaire]);

  // compute total questions per group
useEffect(() => {
  if (!currentQuestionnaire) return;  
  const newTotalQuestionsMap: GroupQuestionMap = {}; 

  currentQuestionnaire.groups.forEach(group => {
    newTotalQuestionsMap[group.id] = group.questions.map(q => q.question?.externalId) 
    .filter(Boolean) as string[]; 
  });

  setTotalQuestionsMap(newTotalQuestionsMap); 
}, [currentQuestionnaire]); 

  // Update URL when filters change
  useEffect(() => {
    if (isLoading) return;
    const newParams = new URLSearchParams(searchParams);

  // Update search term
  updateParam(newParams, "search", searchTerm || null);

 // Update carriers
const carriersValue = filterCarriers.length > 0 
  ? filterCarriers.map(c => [c.carrier, c.classcode].filter(Boolean).join("-")).join(",") 
  : null;
  updateParam(newParams, "carriers", carriersValue);

 // Update states
const statesValue = filterStates.length > 0 
  ? filterStates.map(state => state.shortName).join(",") 
  : null;
   updateParam(newParams, "states", statesValue);

setSearchParams(newParams, { replace: true });
}, [searchTerm, filterCarriers, filterStates, setSearchParams]);


  const handleGroupSelection = (group: QuestionnaireQuestionGroupEntity) => {
    // When selecting a group, remove question param but keep other params
    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", group.partOrder.toString());
    newParams.delete("question");
    setSearchParams(newParams);
    setSelectedGroup(group);
    setSelectedQuestion(undefined);
    
    //Only set filtered questions if the group is different from the current group
    if (selectedGroup !== group) {}
  };
 const questionMap: Record<string, QuestionnaireQuestionEntity> = useMemo(() => {
  if (!currentQuestionnaire) {
    return {};
  }
  return currentQuestionnaire.groups.flatMap(group => group.questions).reduce((acc, question) => {
    acc[question.question?.externalId || ""] = question;
    return acc;
  }, {} as Record<string, QuestionnaireQuestionEntity>);

 }, [currentQuestionnaire]);
  const referenceMap: Record<string, string> = useMemo(() => {
    if (!currentQuestionnaire) {
      return {};
    }
    const { map } = calculateQuestionExternalIdMap(currentQuestionnaire);
    return map as Record<string, string>;
  }, [currentQuestionnaire]);

  const isVisible = (rules: QuestionRuleGroupEntity[]) => {
    const displayRules = rules.map(group => ({
      rule: ruleGroupToString(group),
      action: group.actionKind as QuestionVisibility,
    }));

    const matchesStates =
      displayRules.length === 0 ||
      !displayRules.some(dr => dr.rule.includes("$State")) ||
      displayRules
        .filter(dr => dr.rule.includes("$State"))
        .some(
          dr =>
            (dr.action === QuestionVisibility.Show && filterStates.some(state => dr.rule.includes(state.shortName))) ||
            (dr.action === QuestionVisibility.Hidden && !filterStates.some(state => dr.rule.includes(state.shortName))),
        );

    const matchedClassCodes =
      displayRules.length === 0 ||
      !filterCarriers.some(v => !!v.classcode) ||
      !displayRules.some(dr => dr.rule.includes("ClassCode")) ||
      displayRules
        .filter(dr => dr.rule.includes("ClassCode"))
        .some(
          dr =>
            (dr.action === QuestionVisibility.Show &&
              filterCarriers
                .filter(v => v.classcode)
                .some(
                  c =>
                    dr.rule.toLowerCase().includes(c.carrier.toLowerCase()) &&
                    (!c.classcode ||
                      dr.rule
                        .split(",")
                        .map(v => v.trim().replace("(", "").replace(")", ""))
                        .includes(c.classcode)),
                )) ||
            (dr.action === QuestionVisibility.Hidden &&
              !filterCarriers.some(
                c =>
                  dr.rule.toLowerCase().includes(c.carrier.toLowerCase()) &&
                  (!c.classcode ||
                    dr.rule
                      .split(",")
                      .map(v => v.trim().replace("(", "").replace(")", ""))
                      .includes(c.classcode)),
              )),
        );

    return matchesStates && matchedClassCodes;
  };

  const handleQuestionSelection = (question: QuestionnaireQuestionEntity) => {
    // When selecting a question, keep page param and update question param
    const newParams = new URLSearchParams(searchParams);
    newParams.set("question", question.question?.externalId || "");
    setSearchParams(newParams);

    if (question.question?.values) {
      setSelectedQuestion({
        ...question,
        question: {
          ...question.question,
          values: question.question.values.filter(v => {
            if (!v?.visibility?.length) {
              return true;
            }

            return isVisible(v.visibility as never);
          }),
        },
      });
    } else {
      setSelectedQuestion(question);
    }
  };

  // Handle URL params for initial load and navigation
  useEffect(() => {
    if (!currentQuestionnaire) return;

    const page = searchParams.get("page");
    const questionId = searchParams.get("question");

    if (page) {
      const group = currentQuestionnaire.groups.find(g => g.partOrder === parseInt(page));
      if (group) {
        setSelectedGroup(group);
        if (questionId) {
          const question = group.questions.find(q => q.question?.externalId === questionId);
          if (question) {
            setSelectedQuestion(question);
          }
        }
      }
    }
  }, [searchParams, currentQuestionnaire]);

  // Combined filter effect
  useEffect(() => {
    if (!currentQuestionnaire) return;
    
    const newFilteredQuestionsMap = getFilteredQuestionsMap(currentQuestionnaire, filterCarriers, searchTerm, filterStates, isVisible);
    setFilterQuestionsMap(newFilteredQuestionsMap);
  }, [filterCarriers, filterStates, searchTerm, selectedGroup, currentQuestionnaire, selectedQuestion]);

  const handleSetFilterCarriers = (carriers: CarrierFilter[]) => {
    setFilterCarriers(carriers);
  };

  const handleSetFilterStates = (states: StateFilter[]) => {
    setFilterStates(states);
  };

  const handleSetSearchTerm = (term: string) => {
    setSearchTerm(term);
  };

  const value: DocumentationContextType = {
    selectedGroup,
    selectedQuestion,
    carriers: carriers.map(c => c.name),
    setSelectedGroup: handleGroupSelection,
    setSelectedQuestion: handleQuestionSelection,
    filterCarriers,
    setFilterCarriers: handleSetFilterCarriers,
    searchTerm,
    setSearchTerm: handleSetSearchTerm,
    filteredQuestions,
    referenceMap,
    states: states,
    filterStates,
    totalQuestionsMap,
    filterQuestionsMap,
    setFilterStates: handleSetFilterStates,
    questionMap,
  };

  return <DocumentationContext.Provider value={value}>{children}</DocumentationContext.Provider>;
  
};

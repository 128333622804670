import { useMemo, useState } from "react";
import { cb } from "../../utils/cd";
import classNames from "classnames";
import { useNGN } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { JsonViewer } from "../util/JsonViewer";

enum DebugViewType {
  Validation = "Validation",
  Questions = "Questions",
  CarrierProductSubmissions = "CarrierProductSubmissions",
  Answers = "Answers",
  Context = "Context",
  FieldMap = "FieldMap",
  CarrierProductStatus = "CarrierProductStatus",
}

export const DebugView = () => {
  const [activeTab, setActiveTab] = useState<DebugViewType | null>(null);
  const { validation, carrierProductSubmissions, answers, context, fieldMap, carrierProductStatus } = useNGN();

  const carrierSubmissions = useMemo(() => {
    if (activeTab !== DebugViewType.CarrierProductSubmissions) return [];

    return carrierProductSubmissions();
  }, [carrierProductSubmissions, activeTab]);

  if (!activeTab) {
    return (
      <div
        className={"fixed right-4 bottom-4 rounded px-3 py-0.5 bg-orange-300 text-white shadow cursor-pointer"}
        onClick={cb(setActiveTab, DebugViewType.Answers)}
      >
        Open Debug View
      </div>
    );
  }

  return (
    <div className={"w-full h-[100vh] overflow-y-auto rounded-t-xl fixed bottom-0 bg-white z-50"}>
      <div className={"flex justify-between border-b py-1 mb-2"}>
        <div className={"flex-1 text-center text-xl font-semibold"}>{activeTab}</div>
        <div onClick={cb(setActiveTab, null)} className={"mr-4 text-red-500 cursor-pointer"}>
          <span>X</span> Close
        </div>
      </div>
      <div className={"flex gap-2"}>
        <div className={"w-60 flex flex-col"}>
          {Object.values(DebugViewType).map(v => (
            <div
              key={v}
              onClick={cb(setActiveTab, v)}
              className={classNames("p-1 border-b", { "border-sky-500 bg-sky-100": v === activeTab })}
            >
              {v}
            </div>
          ))}
        </div>
        <div className={"flex-col flex-1 h-[85vh] min-h-[85vh] overflow-y-auto"}>
          {activeTab === DebugViewType.Answers && <JsonViewer data={answers} />}
          {activeTab === DebugViewType.Validation && <JsonViewer data={validation} />}
          {activeTab === DebugViewType.CarrierProductStatus && <JsonViewer data={carrierProductStatus} />}
          {activeTab === DebugViewType.CarrierProductSubmissions && <JsonViewer data={carrierSubmissions} />}
          {activeTab === DebugViewType.Context && <JsonViewer data={context} />}
          {activeTab === DebugViewType.FieldMap && <JsonViewer data={fieldMap} />}
        </div>
      </div>
    </div>
  );
};

import { Questionnaire, QuestionnaireQuestionEntity, QuestionnaireQuestionGroupEntity, QuestionVisibility, RuleGroupKind, QuestionRuleGroupEntity} from "@Savus-Inc/questionnaire-types";
import { CarrierFilter, GroupQuestionMap, StateFilter } from "../DocumentationTypes/QuestionnaireTypes";

const ruleGroupToString = (group: QuestionRuleGroupEntity): string => {
  return group.kind;
};

/**
 * Parses URL filters for carriers.
 */
export const parseCarriersFromURL = (urlCarriers: string | null): CarrierFilter[] => {
  if (!urlCarriers) return [];
  try {
    return decodeURIComponent(urlCarriers)
      .split(",")
      .map(carrier => {
        const [carrierName, classcode] = carrier.split("-");
        return { carrier: carrierName, classcode: classcode || undefined };
      });
  } catch (e) {
    console.error("Failed to parse carriers from URL", e);
    return [];
  }
};

/**
 * Parses URL filters for states.
   @param {string | null} urlStates 
   @param {StateFilter[]} availableStates 
   @returns {StateFilter[]} 
 */
export const parseStatesFromURL = (urlStates: string | null, availableStates: StateFilter[]): StateFilter[] => {
  if (!urlStates) return [];
  try {
    const selectedShortNames = decodeURIComponent(urlStates).split(",");
    return availableStates.filter(state => selectedShortNames.includes(state.shortName));
  } catch (e) {
    console.error("Failed to parse states from URL", e);
    return [];
  }
};

/**
 * Computes the total number of questions per group.
   @param {QuestionnaireEntity} [questionnaire]
   @returns {GroupQuestionMap} 
 */
export const computeTotalQuestionsMap = (questionnaire?: Questionnaire): GroupQuestionMap => {
  if (!questionnaire) return {};
  return questionnaire.groups.reduce((acc: GroupQuestionMap, group: QuestionnaireQuestionGroupEntity) => {
    acc[group.id] = group.questions.map(q => q.question?.externalId).filter(Boolean) as string[];
    return acc;
  }, {} as GroupQuestionMap);
};

/**
 * Computes the filtered questions per group.
   @param {QuestionnaireEntity} [questionnaire] 
   @param {string} [searchTerm=""] 
   @param {CarrierFilter[]} [filterCarriers=[]] 
   @param {(rules: any[]) => boolean} isVisible 
   @returns {GroupQuestionMap} 
 */
export const computeFilteredQuestionsMap = (
 isVisible: (rules: any[]) => boolean,
  questionnaire?: Questionnaire,
  searchTerm = "",
  filterCarriers: CarrierFilter[] = [],

): GroupQuestionMap => {
  if (!questionnaire) return {};
  return questionnaire.groups.reduce((acc: GroupQuestionMap, group: QuestionnaireQuestionGroupEntity) => {
    const filteredQuestions = group.questions.filter(q => {
      const matchesSearch =
        !searchTerm ||
        q.question?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.question?.externalId?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCarriers = filterCarriers.length === 0 || filterCarriers.some(filter => q.showForCarriers.includes(filter.carrier));

      const displayRules = q.ruleGroups.filter((group: { kind: string }) => group.kind === "Visibility");

      return matchesSearch && matchesCarriers && isVisible(displayRules);
    });

    acc[group.id] = filteredQuestions.map(q => q.question?.externalId).filter(Boolean) as string[];
    return acc;
  }, {} as GroupQuestionMap);
};

export const updateParam = (params: URLSearchParams, key: string, value: string | null) => {
    if (value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }
  };

  /**
 * Gets the filtered questions map.
 * @param {Questionnaire} currentQuestionnaire 
 * @param {CarrierFilter[]} filterCarriers
 * @param {string} searchTerm 
 * @param {StateFilter[]} filterStates 
 * @returns {GroupQuestionMap} 
 */

  export const getFilteredQuestionsMap = (
    currentQuestionnaire: Questionnaire,
    filterCarriers: CarrierFilter[],
    searchTerm: string,
    filterStates: StateFilter[],
    isVisible: (rules: any[], filterStates: StateFilter[], filterCarriers: CarrierFilter[]) => boolean
  ): GroupQuestionMap => {
    const newFilteredQuestionsMap: GroupQuestionMap = {};
  
    currentQuestionnaire.groups.forEach(group => {
      const filtered = group.questions.filter(q => {
        const matchesCarriers =
          filterCarriers.length === 0 || filterCarriers.some(filter => q.showForCarriers.includes(filter.carrier));
  
        const matchesSearch =
          !searchTerm ||
          q.question?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          q.question?.externalId?.toLowerCase().includes(searchTerm.toLowerCase());
  
        const displayRules = q.ruleGroups.filter(group => group.kind === RuleGroupKind.Visibility);
  
        return matchesCarriers && matchesSearch && isVisible(displayRules, filterStates, filterCarriers);
      });
  
      newFilteredQuestionsMap[group.id] = filtered.map(q => q.question?.externalId).filter(Boolean) as string[];
    });
  
    return newFilteredQuestionsMap;
  };

  export const getQuestionMap = (currentQuestionnaire: Questionnaire | null): Record<string, QuestionnaireQuestionEntity> => {
    if (!currentQuestionnaire) {
      return {};
    }
    return currentQuestionnaire.groups.flatMap(group => group.questions).reduce((acc, question) => {
      acc[question.question?.externalId || ""] = question;
      return acc;
    }, {} as Record<string, QuestionnaireQuestionEntity>);
  };
  
  export const getReferenceMap = (currentQuestionnaire: Questionnaire | null): Record<string, string> => {
    if (!currentQuestionnaire) {
      return {};
    }
    const { map } = calculateQuestionExternalIdMap(currentQuestionnaire);
    return map as Record<string, string>;
  };
  
  export const isVisible = (
    rules: QuestionRuleGroupEntity[],
    filterStates: StateFilter[],
    filterCarriers: CarrierFilter[]
  ): boolean => {
    const displayRules = rules.map(group => ({
      rule: ruleGroupToString(group),
      action: group.actionKind as QuestionVisibility,
    }));
    const matchesStates =
    displayRules.length === 0 ||
    !displayRules.some(dr => dr.rule.includes("$State")) ||
    displayRules
      .filter(dr => dr.rule.includes("$State"))
      .some(
        dr =>
          (dr.action === QuestionVisibility.Show && filterStates.some(state => dr.rule.includes(state.shortName))) ||
          (dr.action === QuestionVisibility.Hidden && !filterStates.some(state => dr.rule.includes(state.shortName))),
      );

      const matchedClassCodes =
    displayRules.length === 0 ||
    !filterCarriers.some(v => !!v.classcode) ||
    !displayRules.some(dr => dr.rule.includes("ClassCode")) ||
    displayRules
      .filter(dr => dr.rule.includes("ClassCode"))
      .some(
        dr =>
          (dr.action === QuestionVisibility.Show &&
            filterCarriers
              .filter(v => v.classcode)
              .some(
                c =>
                  dr.rule.toLowerCase().includes(c.carrier.toLowerCase()) &&
                  (!c.classcode ||
                    dr.rule
                      .split(",")
                      .map((v: string) => v.trim().replace("(", "").replace(")", ""))
                      .includes(c.classcode)),
              )) ||
          (dr.action === QuestionVisibility.Hidden &&
            !filterCarriers.some(
              c =>
                dr.rule.toLowerCase().includes(c.carrier.toLowerCase()) &&
                (!c.classcode ||
                  dr.rule
                    .split(",")
                    .map((v: string) => v.trim().replace("(", "").replace(")", ""))
                    .includes(c.classcode)),
            )),
      );

  return matchesStates && matchedClassCodes;
};

function calculateQuestionExternalIdMap(currentQuestionnaire: Questionnaire): { map: any; } {
  throw new Error("Function not implemented.");
}

import React, { useState } from "react";
import { CodeWindow } from "../../Documentation/components/CodeWindow";
import { CopyAction } from "../../util/ItemActions";
import { compareJson, DiffEntry, groupDiffResults } from "../utils/compare-json";
import { getValueByPath, parseFileContent } from "../utils/json-utils";

interface JsonDiffProps {
  className?: string;
}

export const JsonDiff: React.FC<JsonDiffProps> = ({ className }) => {
  const [baseFile, setBaseFile] = useState<File | null>(null);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [diffResults, setDiffResults] = useState<DiffEntry[] | undefined>(undefined);
  const [entryPoint, setEntryPoint] = useState("");
  const [summarizationKey, setSummarizationKey] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  const [copiedOld, setCopiedOld] = useState(false);
  const [copiedNew, setCopiedNew] = useState(false);

  const handleDiffFiles = async () => {
    if (!baseFile || !newFile) {
      setError("Please select both files");
      return;
    }

    try {
      const baseContent = await baseFile.text();
      const newContent = await newFile.text();

      const baseJson = parseFileContent(baseContent);
      const newJson = parseFileContent(newContent);

      const baseData = entryPoint ? getValueByPath(baseJson, entryPoint) : baseJson;
      const newData = entryPoint ? getValueByPath(newJson, entryPoint) : newJson;

      if (!baseData || !newData) {
        throw new Error("Invalid entry point path");
      }

      const differences = compareJson(
        baseData,
        newData,
        undefined,
        [],
        summarizationKey,
        undefined,
        true,
      ).filter(diff => diff.header);

      setDiffResults(differences);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    }
  };

  const toggleGroup = (groupKey: string) => {
    const newExpanded = new Set(expandedGroups);
    if (newExpanded.has(groupKey)) {
      newExpanded.delete(groupKey);
    } else {
      newExpanded.add(groupKey);
    }
    setExpandedGroups(newExpanded);
  };

  return (
    <div className={`border rounded-lg p-4 ${className}`}>
      <h2 className="text-lg mb-4">JSON Diff Comparison</h2>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-sm text-gray-600">Base JSON File</label>
          <input
            type="file"
            accept=".json"
            onChange={e => e.target.files && setBaseFile(e.target.files[0])}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm text-gray-600">New JSON File</label>
          <input
            type="file"
            accept=".json"
            onChange={e => e.target.files && setNewFile(e.target.files[0])}
            className="w-full p-2 border rounded"
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 mt-4">
        <label className="text-sm text-gray-600">Entry Point (optional)</label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={entryPoint}
          onChange={e => setEntryPoint(e.target.value)}
          placeholder="e.g., underwritingQuestionsResponse.underwritingQuestionsData"
        />
      </div>

      <div className="flex flex-col gap-2 mt-4">
        <label className="text-sm text-gray-600">Summarization Key (optional)</label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={summarizationKey}
          onChange={e => setSummarizationKey(e.target.value)}
          placeholder="e.g., ruleCode"
        />
      </div>

      {error && <div className="text-red-500 mt-2">{error}</div>}

      <button
        className="mt-4 w-48 py-1 bg-gray-700 text-white rounded"
        onClick={handleDiffFiles}
      >
        Compare Files
      </button>

      {diffResults && diffResults.length > 0 && (
        <div className="mt-4 space-y-4">
          <h3 className="text-lg mb-2">Differences Found:</h3>
          <div className="space-y-2">
            {Object.entries(groupDiffResults(diffResults)).map(([groupKey, groupDiffs]) => (
              <div key={groupKey} className="border rounded-lg overflow-hidden">
                <button
                  onClick={() => toggleGroup(groupKey)}
                  className="w-full p-3 bg-light hover:bg-secondary-lighter flex items-center justify-between text-left"
                >
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <span className="text-primary font-medium">{groupKey}</span>
                      <span className="text-secondary-text text-sm">
                        ({groupDiffs.length} change{groupDiffs.length !== 1 ? "s" : ""})
                      </span>
                    </div>
                    <CopyAction value={groupKey} small />
                  </div>
                  <svg
                    className={`w-5 h-5 transform transition-transform ${
                      expandedGroups.has(groupKey) ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>

                {expandedGroups.has(groupKey) && (
                  <div className="border-t">
                    {groupDiffs.map((diff, index) => (
                      <div key={index} className="border-b last:border-b-0">
                        <div className="p-3 bg-light/50">
                          <span className="font-mono text-sm text-secondary-text">{diff.path}</span>
                        </div>
                        <div className="grid grid-cols-2 gap-4 p-4">
                          <div>
                            <CodeWindow
                              title="Old Value"
                              content={
                                diff.oldValue === undefined
                                  ? "// No Previous Value"
                                  : JSON.stringify(diff.oldValue, null, 2)
                              }
                              onCopy={() => {
                                navigator.clipboard.writeText(JSON.stringify(diff.oldValue, null, 2));
                                setCopiedOld(true);
                                setTimeout(() => setCopiedOld(false), 2000);
                              }}
                              copied={copiedOld}
                            />
                          </div>
                          <div>
                            <CodeWindow
                              title="New Value"
                              content={
                                diff.newValue === undefined
                                  ? "// Value Was Removed"
                                  : JSON.stringify(diff.newValue, null, 2)
                              }
                              onCopy={() => {
                                navigator.clipboard.writeText(JSON.stringify(diff.newValue, null, 2));
                                setCopiedNew(true);
                                setTimeout(() => setCopiedNew(false), 2000);
                              }}
                              copied={copiedNew}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {diffResults && diffResults.length === 0 && (
        <div className="mt-10 flex flex-col items-center justify-center p-6 border rounded-lg bg-green-50 text-green-700">
          <h3 className="text-xl font-semibold">No Differences Found</h3>
          <p className="text-center text-sm text-green-600 mt-2">
            The files are identical or there are no detected differences.
          </p>
        </div>
      )}
    </div>
  );
}; 